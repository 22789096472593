import {
  FormField,
  Label,
  NumberInput,
  Sheet,
  TextInput,
  TitleBar,
} from '@dynatrace/strato-components-preview';
import { Button, Flex } from '@dynatrace/strato-components';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ItemInfo } from '../../types/ListItemInfo';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { LenderService } from '../../services/LenderService';
import { Lender } from '../../types/Lender';
import { useAppInfo } from '../../contexts/AppContext';

export interface LenderSheetProps {
  closeDetails: (itemInfo: ItemInfo | undefined) => void;
  show: boolean;
  itemInfo?: ItemInfo;
}

export const LenderSheet = (props: LenderSheetProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const workingOnCall = useRef<boolean>(false);

  const { closeDetails, show, itemInfo } = props;
  const [itemVisible, setItemVisible] = useState(show);
  const lenderDetails = useRef<Lender>();

  const { tenantId } = useAppInfo();

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    formState: { isSubmitSuccessful },
  } = useForm<{
    name: string;
    contactType: string;
    phone: string;
    email: string;
    licensing: string;
    comercialMortgageProducts: string;
    interestRate: number;
    loan2value: number;
    terms: string;
    applicationProcessDetails: string;
    underwritingProcessDetails: string;
    closingCosts: number;
    specializedServices: string;
  }>({
    mode: undefined,
  });

  // discarded or closed the sheet from escape key
  const dismissDetails = () => {
    closeDetails({ visibility: false });
  };

  useEffect(() => {
    // load other data from API
    if (workingOnCall.current === false) {
      workingOnCall.current = true;
      getRequiredData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemInfo?.id, show]);

  useEffect(() => {
    if (itemInfo?.id) {
      trigger();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // get all the other required data for the edit
  // and creating new for loading dropdown data
  const getRequiredData = async () => {
    setLoading(true);
    try {
      // if not its a new project
      if (itemInfo && itemInfo.id) {
        lenderDetails.current = await LenderService.getLenderById<Lender>(itemInfo.id);
        //console.log(lenderDetails.current);
      }
    } catch (error) {
      ShowErrorNotification('Error fetching business data', error);
      setLoading(false);
    } finally {
      if (lenderDetails.current) {
        reset({
          name: lenderDetails.current.name,
          contactType: lenderDetails.current.contactAddress,
          phone: lenderDetails.current.phoneNumber,
          email: lenderDetails.current.email,
          licensing: lenderDetails.current.licensingDetails,
          comercialMortgageProducts: lenderDetails.current.commercialMortgageProducts,
          interestRate: lenderDetails.current.interestRates,
          loan2value: lenderDetails.current.loanToValueRatio,
          terms: lenderDetails.current.terms,
          applicationProcessDetails: lenderDetails.current.applicationProcessDetails,
          underwritingProcessDetails: lenderDetails.current.underwritingGuidelines,
          closingCosts: lenderDetails.current.closingCostsAndFees,
          specializedServices: lenderDetails.current.specializedServices,
        });
      } else {
        reset({});
      }
      setLoading(false);
    }
  };

  // save the information
  const saveDetails = async (values: any) => {
    //console.log('Saving project details', values);
    const updateParams = {
      lenderID: itemInfo?.id,
      tenantID: tenantId,
      name: values.name,
      contactAddress: values.contactType,
      phoneNumber: values.phone,
      email: values.email,
      licensingDetails: values.licensing,
      commercialMortgageProducts: values.comercialMortgageProducts,
      interestRates: values.interestRate,
      terms: values.terms,
      loanToValueRatio: values.loan2value,
      applicationProcessDetails: values.applicationProcessDetails,
      underwritingGuidelines: values.underwritingProcessDetails,
      closingCostsAndFees: values.closingCosts,
      specializedServices: values.specializedServices,
    };
    if (itemInfo?.id) {
      try {
        await LenderService.updateLender(updateParams);
        ShowSuccessNotification('Lender updated successfully');
      } catch (error) {
        ShowErrorNotification('Error updating lender information', error);
        closeDetails({ refreshParent: false });
        return;
      }
    } else {
      try {
        await LenderService.createLenders(updateParams);
        ShowSuccessNotification('Lender created successfully');
      } catch (error) {
        ShowErrorNotification('Error creating lender', error);
        closeDetails({ refreshParent: false });
        return;
      }
    }

    // save the information and then close
    closeDetails({ refreshParent: true });
  };

  const title = itemInfo?.name ? itemInfo.name : 'New Lender';
  const subTitle = itemInfo?.name ? 'Updating lender information' : 'Creating new lender';
  return (
    <Sheet show={itemVisible} onDismiss={dismissDetails}>
      <form onSubmit={handleSubmit(saveDetails)} onReset={() => reset()} noValidate>
        <Flex flexDirection='column' margin={8} padding={0} gap={8}>
          <TitleBar>
            <TitleBar.Title> {title}</TitleBar.Title>
            <TitleBar.Subtitle> {subTitle}</TitleBar.Subtitle>
            <TitleBar.Action style={{ display: 'flex', alignItems: 'center' }}>
              <Flex flexDirection='row' gap={8}>
                <Button
                  width='75px'
                  onClick={() => {
                    setItemVisible(false);
                    dismissDetails();
                  }}
                  variant='default'
                >
                  Discard
                </Button>
                <Button width='60px' type='submit' variant='accent' color='primary' disabled={isSubmitSuccessful}>
                  Save
                </Button>
              </Flex>
            </TitleBar.Action>
          </TitleBar>
          <LoadingStateComponent loading={loading} />

          {!loading && lenderDetails && (
            <Flex flexDirection='column' width={512} minWidth={512} gap={16}>
              <Controller
                name='name'
                defaultValue={itemInfo?.name ?? ''}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Lender name is required.',
                  },
                  maxLength: {
                    value: 200,
                    message: 'Please enter a valid lender name.',
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormField required>
                    <Label>Lender name</Label>
                    <TextInput
                      placeholder='Enter lender name'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        hint: error?.message,
                      }}
                      {...field}
                    />
                  </FormField>
                )}
              />
              <Controller
                name='contactType'
                control={control}
                rules={{
                  maxLength: {
                    value: 200,
                    message: 'Please enter contact type.',
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormField>
                    <Label>Contact business type</Label>
                    <TextInput
                      placeholder='Enter contact business type'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        hint: error?.message,
                      }}
                      {...field}
                    />
                  </FormField>
                )}
              />
              <Controller
                name='phone'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormField>
                    <Label>Phone</Label>
                    <TextInput
                      placeholder='Enter phone number'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        hint: error?.message,
                      }}
                      {...field}
                    />
                  </FormField>
                )}
              />
              <Controller
                name='email'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormField>
                    <Label>Email</Label>
                    <TextInput
                      placeholder='Enter email'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        hint: error?.message,
                      }}
                      {...field}
                    />
                  </FormField>
                )}
              />
              <Controller
                name='licensing'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormField>
                    <Label>Licensing details</Label>
                    <TextInput
                      placeholder='Enter licensing details'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        hint: error?.message,
                      }}
                      {...field}
                    />
                  </FormField>
                )}
              />
              <Controller
                name='comercialMortgageProducts'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormField>
                    <Label>Commercial mortgage products</Label>
                    <TextInput
                      placeholder='Enter commercial mortgage products'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        hint: error?.message,
                      }}
                      {...field}
                    />
                  </FormField>
                )}
              />
              <Controller
                name='interestRate'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormField>
                    <Label>Interest rate</Label>
                    <NumberInput
                      placeholder='eg. 6.1'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        //hint: error?.message || "We offer sizes from 30 to 47",
                      }}
                      {...field}
                    />
                  </FormField>
                )}
              />
              <Controller
                name='loan2value'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormField>
                    <Label>Loan to value ratio</Label>
                    <NumberInput
                      placeholder='eg. 1'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        //hint: error?.message || "We offer sizes from 30 to 47",
                      }}
                      {...field}
                    />
                  </FormField>
                )}
              />
              <Controller
                name='terms'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormField>
                    <Label>Terms</Label>
                    <TextInput
                      placeholder='Enter terms'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        hint: error?.message,
                      }}
                      {...field}
                    />
                  </FormField>
                )}
              />
              <Controller
                name='applicationProcessDetails'
                control={control}
                rules={{
                  maxLength: {
                    value: 200,
                    message: 'Please enter a valid lender name.',
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormField>
                    <Label>Application process details</Label>
                    <TextInput
                      placeholder='Enter application process details'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        hint: error?.message,
                      }}
                      {...field}
                    />
                  </FormField>
                )}
              />
              <Controller
                name='underwritingProcessDetails'
                control={control}
                rules={{
                  maxLength: {
                    value: 200,
                    message: 'Please enter a valid underwriting process details.',
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormField>
                    <Label>Underwriting process details:</Label>
                    <TextInput
                      placeholder='Enter underwriting process details'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        hint: error?.message,
                      }}
                      {...field}
                    />
                  </FormField>
                )}
              />
              <Controller
                name='closingCosts'
                control={control}
                rules={{
                  maxLength: {
                    value: 200,
                    message: 'Please enter closing costs.',
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormField>
                    <Label>Closing costs</Label>
                    <NumberInput
                      placeholder='Enter closing costs'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        hint: error?.message,
                      }}
                      {...field}
                    />
                  </FormField>
                )}
              />
              <Controller
                name='specializedServices'
                control={control}
                rules={{
                  maxLength: {
                    value: 200,
                    message: 'Please enter a specialized services.',
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormField>
                    <Label>Specialized Services</Label>
                    <TextInput
                      placeholder='Enter specialized services'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        hint: error?.message,
                      }}
                      {...field}
                    />
                  </FormField>
                )}
              />
            </Flex>
          )}
        </Flex>
      </form>
    </Sheet>
  );
};
