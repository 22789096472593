import { Surface } from '@dynatrace/strato-components/layouts';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { ChevronDownIcon, ChevronRightIcon, DeleteIcon, UploadIcon } from '@dynatrace/strato-icons';
import { Link, Strong, Text } from '@dynatrace/strato-components/typography';
import { StatusConfig } from './status-config';
import { UploadRequestDetails } from '../../types/Request';
import { Colors } from '@dynatrace/strato-design-tokens';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { FileNameState } from '../../types/File';
import { blobToData, handleUploadFileChange, handleUploadFileRemove, UploadFileKeys } from './upload-utils';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { format } from 'date-fns';
import { hideElement } from '../projects/ProjectStyles.css';
import { Chip } from '@dynatrace/strato-components-preview/content';

export interface InCompleteTasksProps {
  incompleteTasks: UploadRequestDetails[];
  toggleExpand: (projectRequestSentId: number) => void;
  expandedSections: { [key: number]: boolean };
  uploadFileKeys: UploadFileKeys;
  reloadUploadDetails: () => void;
}

export const IncompleteTasks = (props: InCompleteTasksProps) => {
  const { incompleteTasks, toggleExpand, expandedSections, reloadUploadDetails, uploadFileKeys } = props;

  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [fileDataMap, setFileDataMap] = useState<{ [key: string]: { files: File[]; details: any } }>({});
  const [fileName, setFileName] = useState<FileNameState>({});

  // Define the click event handler
  const handleClick = (index: number) => {
    if (fileInputRefs.current[index]) {
      fileInputRefs.current[index]!.click();
    }
  };

  // Define the callback ref function
  const setInputRef = (index: number, element: HTMLInputElement | null) => {
    if (element) {
      element.onclick = () => handleClick(index); // Add the onClick event handler
    }
    fileInputRefs.current[index] = element;
  };

  // when file is removed
  const handleFileRemove = (documentTypeAutoId: number, projectRequestSentId: number) => {
    handleUploadFileRemove(documentTypeAutoId, projectRequestSentId, setFileDataMap, setFileName);
    if (Object.keys(fileDataMap).length === 0) {
      setIsSubmit(false);
    }
  };

  useEffect(() => {
    if (Object.keys(fileDataMap).length === 0) {
      setIsSubmit(false);
    }
  }, [fileDataMap]);

  // when file is uploaded
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>, details: UploadRequestDetails) => {
    handleUploadFileChange(event, details, setFileDataMap, setFileName);
    setIsSubmit(true);
  };
  const openBlobOnClick = (base64Data: string, fileName: string) => {
    // Convert base64 to a Blob
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });

    // Create a download link
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;

    link.click();
  };

  const handleSave = async () => {
    if (Object.keys(fileDataMap).length === 0) {
      ShowErrorNotification('Please select a file to upload');
      return;
    }

    try {
      setLoading(true);
      const { token, fileFormat, projectId, tenantId, peopleId, request } = uploadFileKeys;

      let apiCalls: Promise<void>[] = [];
      Object.keys(fileDataMap).forEach((documentTypeAutoId) => {
        const { files, details } = fileDataMap[documentTypeAutoId];
        files.forEach((file, index) => {
          if (index === files.length - 1) {
            apiCalls.push(blobToData(file, index, request, tenantId, projectId, peopleId, fileFormat, token, details));
          }
        });
      });

      const data = await Promise.all(apiCalls);
      if (data) {
        ShowSuccessNotification('Files uploaded successfully');
        reloadUploadDetails();
      }
    } catch (error) {
      ShowErrorNotification('Error uploading files', error);
    } finally {
      setLoading(false);
      setIsSubmit(false);
    }
  };

  if (loading) {
    return <LoadingStateComponent loading />;
  }

  return (
    <>
      <Flex flexDirection='column' gap={16}>
        <h3>Incomplete Tasks</h3>
        {incompleteTasks.map((task, index) => (
          <Surface
          padding={12}
            style={{
              backgroundColor: StatusConfig[task.statusName as keyof typeof StatusConfig].backgroundColor,
              color: StatusConfig[task.statusName as keyof typeof StatusConfig].color,
            }}
            key={index}
            elevation='floating'
            height={'auto'}
          >
            <Flex gap={16} flexDirection='column'>
              <Flex
                alignItems='center'
                justifyContent='space-between'
                gap={8}
                onClick={() => toggleExpand(task.projectRequestSentId)}
                onMouseEnter={(e: { currentTarget: { style: { cursor: string; }; }; }) => e.currentTarget.style.cursor = 'pointer'}
              >
                <Flex justifyContent='flex-start' flexDirection='row' gap={8}>
                  <Button
                    size='condensed'
                    style={{ marginTop: '-4px' }}
                  >
                    {expandedSections[task.projectRequestSentId] ? <ChevronDownIcon /> : <ChevronRightIcon />}
                  </Button>
                  <Text as='h4'>{task.label}</Text>
                </Flex>
                <Flex justifyContent='flex-end' marginRight={20}>
                  <Strong>
                    <Text as='h4'>{task.statusName === 'New' ? '' : task.statusName}</Text>
                  </Strong>
                </Flex>
              </Flex>

              {/* Conditionally render additional rows */}
              {expandedSections[task.projectRequestSentId] && (
                <Flex flexDirection='column' columnGap={32}>
                  <Flex flexDirection='row' rowGap={8}>
                    <Surface elevation='floating' className='documentList' title={''} width={'100%'} height={'auto'}>
                      <Text as='p'>{task.instructions}</Text>
                    </Surface>
                    <label htmlFor={`file-input-${index}`} className={hideElement}>
                      Upload File
                    </label>
                    <input
                      type='file'
                      id={`file-input-${index}`}
                      className={hideElement}
                      ref={(el) => setInputRef(index, el)}
                      onChange={(event) => handleFileChange(event, task)}
                    />
                    {(task.statusName === 'New' || task.statusName === 'Rejected') && (
                      <Button
                        size='condensed'
                        variant='emphasized'
                        style={{
                          height: 'auto',
                          width: '10%',
                        }}
                        onClick={() => handleClick(index)}
                      >
                        <Button.Prefix>
                          <UploadIcon />
                        </Button.Prefix>
                        {task.statusName === 'Rejected' ? <Text>Upload again!</Text> : <Text>Upload</Text>}
                      </Button>
                    )}

                    {/* <Button.Prefix><UploadIcon/></Button.Prefix>Upload</Button> */}
                  </Flex>
                  {task.projectRequestDetailsFiles && task.projectRequestDetailsFiles.length > 0 && (
                    <Flex flexDirection='row' padding={8}>
                      <Text as='p' style={{ color: Colors.Text.Neutral.Default, marginTop: '5px' }}>
                        Attached file(s):{' '}
                      </Text>
                      <Flex flexDirection='row'>
                        {task.projectRequestDetailsFiles.map((file, index) => (
                          <Chip
                            size='default'
                            color='primary'
                            as={'a'}
                            href={'#'}
                            onClick={() => {
                              openBlobOnClick(
                                file.fileData,
                                file.filePath.split(task.projectRequestDetailsId + '/')[1],
                              );
                            }}
                          >
                            {file.filePath.split(task.projectRequestDetailsId + '/')[1]}
                          </Chip>
                        ))}
                      </Flex>
                    </Flex>
                  )}

                  {fileName[task.projectRequestSentId] && (
                    <Surface elevation='raised' padding={12}>
                      <Flex justifyContent='space-between' alignContent='center'>
                        <Flex justifyContent='center' justifyItems='center' flexDirection='row' gap={8}>
                          <Strong>Uploaded file: </Strong>
                          <Chip variant='emphasized' color='primary'>
                            {fileName[task.projectRequestSentId].name}
                          </Chip>
                        </Flex>
                        <Text>{task.updatedDate ? format(task.updatedDate, 'MMM dd, yyyy HH:mm') : 'N/A'}</Text>

                        <Text>{(fileName[task.projectRequestSentId].size / 1024).toFixed(2) + 'kB'}</Text>
                        <Button
                          size='condensed'
                          color='critical'
                          onClick={() => handleFileRemove(task.documentTypeAutoId, task.projectRequestSentId)}
                        >
                          <DeleteIcon />
                        </Button>
                      </Flex>
                    </Surface>
                  )}
                </Flex>
              )}
            </Flex>
          </Surface>
        ))}
      </Flex>
      {/* Submit Section */}
      <Flex flexDirection='row' justifyContent='flex-end' marginTop='auto' marginRight={8}>
        <Button
          variant='accent'
          style={{ height: '50px' }}
          width={'200px'}
          className='submitButton'
          onClick={handleSave}
          disabled={!isSubmit}
        >
          Submit
        </Button>
      </Flex>
    </>
  );
};
