import { SelectV2 } from '@dynatrace/strato-components-preview/forms';
import { Flex } from '@dynatrace/strato-components/layouts';
import { format, parseISO } from 'date-fns';
import { StatusDetails } from '../../../../types/Status';

// table main columns
export const columns = [
    {
      id: 'projectRequestId',
      header: 'Request Id',
      accessor: 'projectRequestId',
      autoWidth: true,
      columnType: 'number',
    },
    {
      id: 'projectRequestName',
      header: 'Request name',
      accessor: 'projectRequestName',
      width: 280,
    },
    {
      id: 'createdOn',
      header: 'Created on',
      accessor: 'createdOn',
      columnType: 'date',
      width: 200,
      Cell: ({ value }: any) => {
        try {
          const date = parseISO(value);
          return format(date, 'MMM dd, yyyy HH:mm');
        } catch (error) {
          return 'Invalid date';
        }
      },
    },
    {
      id: 'assignedCount',
      header: 'Receipients count',
      accessor: 'assignedCount',
      autoWidth: true,
      columnType: 'number',
    },
  ];

// table sub-row columns
export const rowColumns = [
    {
      id: 'assignedto',
      header: 'Assigned to',
      accessor: 'assignedto',
      minWidth: 300,
      columnType: 'text',
    },
    {
      id: 'completed',
      header: 'Completed',
      accessor: 'completed',
      width: 100,
      columnType: 'number',
    },
    {
      id: 'inProgress',
      header: 'In-complete',
      accessor: 'inProgress',
      width: 100,
      columnType: 'number',
    },
  ];

export function getRowDocumentColumns(statusOptions: StatusDetails[], handleSelectChange: any) {
  
  // custom renderer for status cell for document rows.
  const renderStatusNameCell = ({ cell }: any) => {
    const statusValue = cell.value;
    const disableStatusCheck = (statusValue: string) => {
      if (statusValue === 'New' || statusValue === 'Approved') {
        return true;
      }
      return false;
    };
    const disableOptionsCheck = (option: string) => {
      if (option === 'New' || option === 'Pending Approval') {
        return true;
      }
      return false;
    };

    return (
      <Flex
        onKeyDown={(event: { key: string; preventDefault: () => void }) => {
          if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
          }
        }}
      >
        <SelectV2
          value={cell.value}
          onChange={(value: string) => handleSelectChange(value, statusOptions, cell)}
          disabled={disableStatusCheck(statusValue)}
        >
          <SelectV2.Content width={'auto'}>
            {statusOptions?.map((option) => (
              <SelectV2.Option
                key={option.statusName}
                value={option.statusName}
                disabled={disableOptionsCheck(option.statusName)}
              >
                {option.statusName}
              </SelectV2.Option>
            ))}
          </SelectV2.Content>
        </SelectV2>
      </Flex>
    );
  };

  const rowDocumentsColumns = [
      {
        id: 'label',
        header: 'File name',
        accessor: 'label',
        width: 200,
      },
      {
        id: 'instructions',
        header: 'Instructions',
        accessor: 'instructions',
        autoWidth: true,
      },
      {
        id: 'statusName',
        header: 'Status',
        accessor: 'statusName',
        autoWidth: 200,
        cell: renderStatusNameCell,
      },
    ];

  return rowDocumentsColumns;
}
