import { DataTable, Modal, SelectV2, Tooltip, uuidv4 } from '@dynatrace/strato-components-preview';
import { Button, Flex, TextEllipsis } from '@dynatrace/strato-components';
import { useMemo, useRef, useState } from 'react';
import { PlusIcon } from '@dynatrace/strato-icons';
import { NewPersonModal } from '../../people/NewPersonModal';
import { useAppInfo } from '../../../contexts/AppContext';
import { usePeopleByTenantId } from '../../../hooks/use-minerva-data';
import { ShowErrorNotification, ShowSuccessNotification, ShowValidationBanner } from '../../../utils/Notifications';
import { PersonaService } from '../../../services/PersonaService';
import { ProjectPeopleRelation } from '../../../types/Project';
import { ModalParentType, PARENT_TYPE_BUSINESS, PARENT_TYPE_NONE, PARENT_TYPE_PROJECT } from '../../../types/Types';
import { ClientPersona } from '../../../types/Business';

interface NewProjectPeopleRelationModalProps {
  parentType: ModalParentType;
  parentId: number;
  currentProjectPeopleList: ProjectPeopleRelation[];
  currentBusinessPeopleList: ClientPersona[];
  onDismiss: (update?: boolean) => void;
}

export const NewProjectPeopleRelationModal = (props: NewProjectPeopleRelationModalProps) => {
  const { onDismiss, parentId } = props;

  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [showModal, setShowModal] = useState(true);
  const [showNewPersonModal, setShowNewPersonModal] = useState<boolean>(false);
  const selectedPeople = useRef<any>([]);

  const { tenantId, tenantPersonaList, peoplePersonas } = useAppInfo();

  const { isLoading, data: tenantPeople, error, refetch } = usePeopleByTenantId(tenantId);
  if (error) {
    ShowErrorNotification('Error loading people information', error);
  }

  const onSelectPersona = (row: any, personaAutoId: number) => {
    selectedPeople.current?.forEach((person: any) => {
      if (person.clientId === row.clientId) {
        person.personaAutoId = personaAutoId;
      }
    });
    setShowErrorBanner(false);
    if (selectedPeople.current?.length !== 0) {
      if (isValidSelection()) {
        setShowErrorBanner(false);
        setDisableConfirm(false);
      } else {
        setShowErrorBanner(true);
        setDisableConfirm(true);
      }
    }
  };

  const projectPeopleColumns = useMemo(
    () => [
      {
        id: 'clientName',
        header: 'Name',
        accessor: 'clientName',
        autoWidth: true,
        cell: (cell: any) => (
          <DataTable.Cell>
            <Tooltip placement='bottom' text={`${cell.row.original.firstName}, ${cell.row.original.lastName}`}>
              {
                <TextEllipsis truncationMode='end'>
                  {`${cell.row.original.lastName}, ${cell.row.original.firstName}`}
                </TextEllipsis>
              }
            </Tooltip>
          </DataTable.Cell>
        ),
      },
      {
        id: 'personaName',
        header: 'Project role',
        accessor: 'personaName',
        width: 200,
        cell: (cell: any) => (
          <DataTable.Cell>
            <Flex
              onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                if (event.key === 'Enter') {
                  event.preventDefault(); // Prevent form submission
                }
              }}
            >
              <SelectV2 onChange={(value) => onSelectPersona(cell.row.original, value as number)}>
                <SelectV2.Trigger placeholder={'Select project role'} />
                <SelectV2.Content style={{ maxWidth: '300px' }} width='150px'>
                  {props.parentType === PARENT_TYPE_PROJECT &&
                    tenantPersonaList &&
                    tenantPersonaList.map((persona, index) => (
                      <SelectV2.Option key={index} value={persona.personaAutoId}>
                        {persona.personaName}
                      </SelectV2.Option>
                    ))}
                  {props.parentType === PARENT_TYPE_BUSINESS &&
                    peoplePersonas &&
                    peoplePersonas.map((persona, index) => (
                      <SelectV2.Option key={index} value={persona.personaId}>
                        {persona.personaName}
                      </SelectV2.Option>
                    ))}
                </SelectV2.Content>
              </SelectV2>
            </Flex>
          </DataTable.Cell>
        ),
      },
      {
        id: 'email',
        header: 'Email',
        accessor: 'email',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleCancel = () => {
    setShowModal(false);
    onDismiss(false);
  };

  const handleConfirm = () => {
    if (props.parentType === PARENT_TYPE_PROJECT) {
      saveProjectPeopleRelation();
    }
    if (props.parentType === PARENT_TYPE_BUSINESS) {
      saveBusinessPeopleRelation();
    }
    setShowModal(false);
    onDismiss(false);
  };

  const onRowSelected = (obj: Record<string, boolean>, data: any[], trigger: 'user' | 'internal') => {
    setDisableConfirm(data.length === 0);
    selectedPeople.current = [];
    data.forEach((row) => {
      row.isPersonSelected = true;
      selectedPeople.current.push(row);
    });
    setShowErrorBanner(false);
    if (data.length !== 0) {
      if (isValidSelection()) {
        setShowErrorBanner(false);
        setDisableConfirm(false);
      } else {
        setShowErrorBanner(true);
        setDisableConfirm(true);
      }
    }
  };

  function isValidSelection() {
    let isValid = true;
    selectedPeople.current?.forEach((person: any) => {
      if (person.isPersonSelected && person.personaAutoId === undefined) {
        isValid = false;
      }
    });
    return isValid;
  }

  const saveProjectPeopleRelation = async () => {
    try {
      const relationData = selectedPeople.current.map((person: any) => ({
        projectPersonId: 0,
        tenantId: tenantId,
        projectId: parentId,
        peopleId: person.clientId,
        personaAutoId: person.personaAutoId,
        modifiedOn: new Date().toISOString(),
      }));
      await PersonaService.createProjectPeopleRelation(relationData);
      ShowSuccessNotification('Project people relation created successfully');
      onDismiss(true);
    } catch (error) {
      ShowErrorNotification('Error creating project people relation', error);
      onDismiss();
    }
  };

  const saveBusinessPeopleRelation = async () => {
    try {
      const relationData = selectedPeople.current.map((person: any) => ({
        businessId: parentId,
        clientBusinessId: 0,
        clientId: person.clientId,
        personaId: person.personaAutoId,
      }));
      await PersonaService.createClintRelation(relationData);
      ShowSuccessNotification('Business people relation created successfully');
      onDismiss(true);
    } catch (error) {
      ShowErrorNotification('Error creating Business people relation', error);
      onDismiss();
    }
  };

  return (
    <Modal
      show={showModal}
      title={'Add people to this project'}
      size={'medium'}
      dismissible={false}
      footer={
        <Flex justifyContent='space-between' width='100%'>
          <Button
            onClick={() => {
              setShowNewPersonModal(true);
            }}
            variant='emphasized'
            color='neutral'
          >
            <Button.Prefix>
              <PlusIcon />
            </Button.Prefix>
            New person
          </Button>

          <Flex justifyContent='flex-end' gap={8}>
            <Button onClick={handleCancel} variant='default'>
              Discard
            </Button>
            <Button
              onClick={handleConfirm}
              type='submit'
              variant='accent'
              color='primary'
              disabled={disableConfirm || props.parentType === PARENT_TYPE_NONE}
            >
              Confirm
            </Button>
          </Flex>
        </Flex>
      }
    >
      {showErrorBanner && ShowValidationBanner('All selected rows should have a valid', 'Project role')}
      <DataTable
        loading={isLoading}
        sortable
        columns={projectPeopleColumns}
        data={
          tenantPeople
            ? tenantPeople.filter(
                (person) =>
                  !(props.parentType === PARENT_TYPE_PROJECT
                    ? props.currentProjectPeopleList.some((ppr) => ppr.peopleId === person.clientId)
                    : props.currentBusinessPeopleList.some((ppr) => ppr.clientId === person.clientId)),
              )
            : []
        }
        variant={{ rowSeparation: 'zebraStripes' }}
        sortBy={{ id: 'clientName', desc: false }}
        selectableRows
        onRowSelectionChange={onRowSelected}
      >
        <DataTable.EmptyState>No persons available.</DataTable.EmptyState>
      </DataTable>
      {showNewPersonModal && (
        <NewPersonModal
          key={uuidv4()}
          onDismiss={(refresh) => {
            setShowNewPersonModal(false);
            if (refresh) {
              refetch();
            }
          }}
        />
      )}
    </Modal>
  );
};
