import {
  DataTable,
  DateTimePicker,
  FormField,
  Label,
  NumberInput,
  Sheet,
  Tab,
  TableRowActions,
  Tabs,
  TextArea,
  TextInput,
  TitleBar,
  Tooltip,
  uuidv4,
} from '@dynatrace/strato-components-preview';
import { Button, Flex, TextEllipsis } from '@dynatrace/strato-components';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ItemInfo } from '../../types/ListItemInfo';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { BusinessService } from '../../services/BusinessService';
import { BusinessDetail, ClientPersona } from '../../types/Business';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { useAppInfo } from '../../contexts/AppContext';
import { DeleteIcon, DescriptionIcon, GroupIcon, PlusIcon } from '@dynatrace/strato-icons';
import { groupHeaderFontStyle } from '../GeneralStyles.css';
import { NewProjectPeopleRelationModal } from '../projects/project-relations/NewProjectPeopleRelationModal';
import { PARENT_TYPE_BUSINESS } from '../../types/Types';
import { PeopleService } from '../../services/PeopleService';

export interface BusinessSheetProps {
  closeDetails: (itemInfo?: ItemInfo) => void;
  show: boolean;
  itemInfo?: ItemInfo;
}

export const BusinessSheet = (props: BusinessSheetProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPeopleRelations, setLoadingPeopleRelations] = useState<boolean>(false);

  const workingOnCall = useRef<boolean>(false);

  const { closeDetails, show, itemInfo } = props;
  const [state, setState] = useState(show);
  const businessDetails = useRef<BusinessDetail | undefined>(undefined);

  const [peopleList, setPeopleList] = useState<ClientPersona[] | []>([]);

  const [showExistingPersonModal, setShowExistingPersonModal] = useState<boolean>(false);

  const { tenantId, userId } = useAppInfo();
  const doNotSubmit = false;

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    formState: { isSubmitSuccessful },
  } = useForm<{
    businessId: number;
    tenantId: number;
    userId: string;
    businessName: string;
    businessType: string;
    registrationNumber: string;
    businessAddress: string;
    industry: string;
    annualRevenue: number;
    incorporationDate: string | null;
    documentFolder: string;
  }>({
    mode: undefined,
  });

  // discarded or closed the sheet from escape key
  const dismissDetails = () => {
    closeDetails({ visibility: false });
  };

  useEffect(() => {
    // load other data from API
    if (workingOnCall.current === false) {
      workingOnCall.current = true;
      getRequiredData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemInfo?.id, show]);

  useEffect(() => {
    if (itemInfo?.id) {
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const businessPeopleColumns = useMemo(
    () => [
      {
        id: 'clientName',
        header: 'Name',
        accessor: 'clientName',
        width: 300,
        cell: (cell: any) => (
          <DataTable.Cell>
            <Tooltip placement='bottom' text={`${cell.row.original.firstName}, ${cell.row.original.lastName}`}>
              {
                <TextEllipsis truncationMode='end'>
                  {`${cell.row.original.lastName}, ${cell.row.original.firstName}`}
                </TextEllipsis>
              }
            </Tooltip>
          </DataTable.Cell>
        ),
      },
      {
        id: 'personaName',
        header: 'Project role',
        accessor: 'personaName',
        autoWidth: true,
        cell: (cell: any) => <DataTable.Cell>{cell.row.original.personas.personaName}</DataTable.Cell>,
      },
      {
        id: 'email',
        header: 'Email',
        accessor: 'email',
      },
    ],
    [],
  );

  // get all the other required data for the edit
  // and creating new for loading dropdown data
  const getRequiredData = async () => {
    setLoading(true);
    try {
      // if not its a new project
      if (itemInfo && itemInfo.id) {
        businessDetails.current = await BusinessService.getClinetDetailsById<BusinessDetail>(itemInfo?.id);
      }
    } catch (error) {
      ShowErrorNotification('Error fetching business data', error);
      setLoading(false);
    } finally {
      if (businessDetails.current) {
        const peopleList: ClientPersona[] = businessDetails.current.clientPersonas;
        setPeopleList(peopleList);

        reset({
          businessId: businessDetails.current.business.businessId,
          tenantId: tenantId,
          userId: userId,
          businessName: businessDetails.current.business.businessName,
          businessType: businessDetails.current.business.businessType,
          registrationNumber: businessDetails.current.business.businessRegistrationNumber,
          businessAddress: businessDetails.current.business.businessAddress,
          industry: businessDetails.current.business.industry,
          annualRevenue: businessDetails.current.business.annualRevenue,
          incorporationDate: businessDetails.current.business.incorporationDate
            ? new Date(businessDetails.current.business.incorporationDate).toISOString()
            : null,
          documentFolder: businessDetails.current.business.rootDocumentFolder,
        });
      } else {
        reset({
          incorporationDate: null,
        });
      }
      setLoading(false);
    }
  };

  // save the information
  const saveDetails = async (values: any) => {
    //console.log('Saving project details', values);
    if (itemInfo?.id) {
      try {
        await BusinessService.updateBusinessById(values);
        ShowSuccessNotification('Business updated successfully');
      } catch (error) {
        ShowErrorNotification('Error updating business information', error);
        closeDetails();
        return;
      }
    } else {
      try {
        values.tenantId = tenantId;
        values.userId = userId;
        await BusinessService.createBusiness(values);
        ShowSuccessNotification('Business created successfully');
      } catch (error) {
        ShowErrorNotification('Error creating business', error);
        closeDetails();
        return;
      }
    }

    // save the information and then close
    closeDetails({ refreshParent: true });
  };

  // updates people business relations post creating a new one or deleting
  const updatePeopleBusinessRelations = async () => {
    try {
      const data: any = await BusinessService.getClinetDetailsById<BusinessDetail>(itemInfo?.id ?? 0);
      setPeopleList(data.clientPersonas);
    } catch (error) {
      ShowErrorNotification('Error updating project business relation', error);
    }
    setLoadingPeopleRelations(false);
  };

  const title = itemInfo?.name ? itemInfo.name : 'New Business';
  const subTitle = itemInfo?.name ? 'Updating business information' : 'Creating new business';
  return (
    <Sheet show={state} onDismiss={dismissDetails}>
      <form onSubmit={handleSubmit(saveDetails)} onReset={() => reset()} noValidate>
        <Flex flexDirection='column' margin={8} gap={8}>
          <TitleBar>
            <TitleBar.Title> {title}</TitleBar.Title>
            <TitleBar.Subtitle> {subTitle}</TitleBar.Subtitle>
            <TitleBar.Action style={{ display: 'flex', alignItems: 'center' }}>
              <Flex flexDirection='row' gap={8}>
                <Button
                  width='75px'
                  onClick={() => {
                    setState(false);
                    dismissDetails();
                  }}
                  variant='default'
                >
                  Discard
                </Button>
                <Button
                  width='60px'
                  type={doNotSubmit ? 'button' : 'submit'}
                  variant='accent'
                  color='primary'
                  disabled={isSubmitSuccessful}
                >
                  Save
                </Button>
              </Flex>
            </TitleBar.Action>
          </TitleBar>
          <LoadingStateComponent loading={loading} />

          {!loading && businessDetails && (
            <Tabs defaultIndex={0}>
              <Tab title={'Business details'} prefixIcon={<DescriptionIcon />}>
                <Flex flexDirection='column' width={512} minWidth={512} gap={16}>
                  <Controller
                    name='businessName'
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Business name is required.',
                      },
                      maxLength: {
                        value: 200,
                        message: 'Please enter a valid business name.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField required>
                        <Label>Business name</Label>
                        <TextInput
                          placeholder='Enter business name'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='businessType'
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Description is required',
                      },
                      minLength: {
                        value: 10,
                        message: 'Description cannot have less than 10 characters.',
                      },
                      maxLength: {
                        value: 250,
                        message: 'Description cannot have more than 250 characters.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField required>
                        <Label>Business type</Label>
                        <TextArea
                          width={'100%'}
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message || 'Please enter between 10 and 250 characters.',
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='registrationNumber'
                    control={control}
                    rules={{
                      maxLength: {
                        value: 200,
                        message: 'Please enter a valid business name.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Registration information</Label>
                        <TextInput
                          placeholder='Enter business registration information'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='annualRevenue'
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please enter a loan value.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField required>
                        <Label>Annual Revenue</Label>
                        <NumberInput
                          aria-label='Annual-revenue'
                          placeholder='eg. 2000000'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message || 'Desired loan amount',
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='industry'
                    control={control}
                    rules={{
                      maxLength: {
                        value: 200,
                        message: 'Please enter a valid information.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Industry</Label>
                        <TextInput
                          placeholder='Industry to which your business belong'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='incorporationDate'
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: 'Please choose an option.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Incorporation date</Label>
                        <DateTimePicker
                          type='date'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          style={{ width: 'max-content' }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='businessAddress'
                    control={control}
                    rules={{
                      maxLength: {
                        value: 200,
                        message: 'Please enter business location',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Business address</Label>
                        <TextInput
                          placeholder='Enter business address'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                </Flex>
              </Tab>
              <Tab title={'Associated people'} prefixIcon={<GroupIcon />} disabled={itemInfo?.id === undefined}>
                <Flex flexDirection='column' width={'50%'} minWidth={325} gap={8}>
                  <Flex
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='end'
                    //marginBottom={4}
                    marginTop={8}
                  >
                    <Label className={groupHeaderFontStyle}>People associated with this project</Label>
                    <Flex>
                      <Button
                        onClick={() => {
                          setShowExistingPersonModal(true);
                        }}
                        variant='accent'
                        color='primary'
                        style={{ margin: '0px 0px 0px auto' }}
                      >
                        <Button.Prefix>
                          <PlusIcon />
                        </Button.Prefix>
                        Add person
                      </Button>
                      {showExistingPersonModal && (
                        <NewProjectPeopleRelationModal
                          key={uuidv4()}
                          parentType={PARENT_TYPE_BUSINESS}
                          parentId={itemInfo?.id ?? 0}
                          currentProjectPeopleList={[]}
                          currentBusinessPeopleList={peopleList ?? []}
                          onDismiss={(update) => {
                            setShowExistingPersonModal(false);
                            if (update) {
                              setLoadingPeopleRelations(true);
                              updatePeopleBusinessRelations();
                            }
                          }}
                        />
                      )}
                    </Flex>
                  </Flex>
                  <DataTable
                    loading={loadingPeopleRelations}
                    sortable
                    columns={businessPeopleColumns}
                    data={peopleList ?? []}
                    variant={{ rowSeparation: 'zebraStripes' }}
                    sortBy={{ id: 'clientName', desc: false }}
                  >
                    <DataTable.EmptyState>No one is associated with this project.</DataTable.EmptyState>
                    <DataTable.UserActions>
                      <DataTable.RowActions>
                        {(row: any) => (
                          <TableRowActions.Group>
                            <TableRowActions.Item
                              onClick={async () => {
                                // deleting the buisness from the project
                                try {
                                  await PeopleService.deleteBusinessDetailsById(
                                    row.original.clientBusinessId,
                                  );
                                  setLoadingPeopleRelations(true);
                                  updatePeopleBusinessRelations();
                                } catch (error) {
                                  ShowErrorNotification('Error deleting people association with the business', error);
                                }
                              }}
                              prefixIcon={<DeleteIcon />}
                            ></TableRowActions.Item>
                          </TableRowActions.Group>
                        )}
                      </DataTable.RowActions>
                    </DataTable.UserActions>
                  </DataTable>
                </Flex>
              </Tab>
            </Tabs>
          )}
        </Flex>
      </form>
    </Sheet>
  );
};
