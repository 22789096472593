// Handle file download
export const handleFileDownload = (file: File) => {
  //console.log(`Downloading: ${file.name}`);
};

// Open file browser for specific section
export const handleBrowseClick = (fileInputRefs:React.MutableRefObject<HTMLInputElement[]>, index: number) => {
  if (fileInputRefs.current[index]) {
    fileInputRefs.current[index].click();
  }
};