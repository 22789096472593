import { Dispatch } from 'react';
import { NotesDetails } from '../../../types/Notes';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../utils/Notifications';
import { RequestDetails } from '../../../types/Request';
import { ProjectService } from '../../../services/ProjectService';
import { UploadService } from '../../../services/UploadService';

export type Document = {
  label: string;
  instructions: string;
  documentTypeName: string;
  assignedto: string;
  statusName: string;
  downloadFilePath: string;
  projectRequestSentId: number;
};

export type AssignedList = {
  assignedto: string;
  completed: number;
  inProgress: number;
  documents: Document[];
};

export type GroupedDocuments = {
  projectRequestId: number;
  projectRequestName: string;
  createdOn: string;
  assignedCount: number;
  assignedList: AssignedList[];
};

export const Notes = (
  newNotes: boolean,
  setNewNotes: React.Dispatch<React.SetStateAction<boolean>>,
  AddNewNotesClick: () => void,
  notes: NotesDetails[],
  refetch: any,
  addNewNotes: string,
  setAddNewNotes: React.Dispatch<React.SetStateAction<string>>,
  truncateNotesText: any,
) => {};

export const downloadFileClick = function (rowInfo: any) {
  //In Local, uncomment the below line
  // window.open(ProjectService.getDownloadFileHeader(rowInfo.original.downloadFilePath));
  //In Dev, uncomment the below Line
  window.open(ProjectService.getDownloadFileHeader(rowInfo.downloadFilePath));
};

export const truncateText = (text: string, maxLines: number) => {
  const lines = text.split('. ');
  if (lines.length <= maxLines) {
    return text;
  }
  return lines.slice(0, maxLines).join('\n') + '...';
};

export const groupDocumentsByProjectRequestId = (projectRequests: RequestDetails[]) => {
  const grouped: { [key: number]: GroupedDocuments } = {};

  projectRequests.forEach((item) => {
    const {
      projectRequestId,
      projectRequestName,
      createdOn,
      label,
      instructions,
      documentTypeName,
      assignedto,
      statusName,
      downloadFilePath,
      projectRequestSentId,
    } = item;

    if (!grouped[projectRequestId]) {
      grouped[projectRequestId] = {
        projectRequestId,
        projectRequestName,
        assignedCount: 1,
        createdOn,
        assignedList: [],
      };
    }

    const assignedGroup = grouped[projectRequestId].assignedList.find((group) => group.assignedto === assignedto);

    if (assignedGroup) {
      assignedGroup.documents.push({
        label,
        instructions,
        documentTypeName,
        assignedto,
        statusName,
        downloadFilePath,
        projectRequestSentId,
      });
    } else {
      grouped[projectRequestId].assignedList.push({
        assignedto,
        completed: 0,
        inProgress: 0,
        documents: [
          { label, instructions, documentTypeName, assignedto, statusName, downloadFilePath, projectRequestSentId },
        ],
      });
    }
  });

  // Update documentsCount for each assigned group
  Object.values(grouped).forEach((project) => {
    const assignedCount = project.assignedList.length;
    project.assignedCount = assignedCount;
    project.assignedList.forEach((group) => {
      const inProgress = group.documents.filter(
        (doc) => doc.statusName !== 'Approved' && doc.statusName !== 'Rejected',
      ).length;
      group.inProgress = inProgress;
      const completed = group.documents.filter(
        (doc) => doc.statusName === 'Approved' || doc.statusName === 'Rejected',
      ).length;
      group.completed = completed;
    });
  });

  return Object.values(grouped);
};

export const handleFileChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  requestId: number,
  uploadRow: any,
  documentIndex: number,
  projectRequests: RequestDetails[],
  projectRequestsApiData: GroupedDocuments[],
  setProjectRequestsApiData: Dispatch<React.SetStateAction<GroupedDocuments[]>>,
  refetchProjectRequests: () => void,
  setLoading: Dispatch<React.SetStateAction<boolean>>,
) => {
  const files = event.target.files;
  const uploadRequest = projectRequests.filter((e) => e.projectRequestSentId === uploadRow.projectRequestSentId)[0];
  projectRequestsApiData.map((request) => {
    if (request.projectRequestId === requestId) {
      return {
        ...request,
        assignedList: request.assignedList.map((assigned) => {
          if (assigned.documents.some((doc) => doc.projectRequestSentId === uploadRow.projectRequestSentId)) {
            return {
              ...assigned,
              documents: assigned.documents.map((doc) => {
                if (
                  doc.projectRequestSentId === uploadRow.projectRequestSentId &&
                  doc.documentTypeName === uploadRequest.documentTypeName
                ) {
                  return {
                    ...doc,
                    // Update any other necessary fields in the document here
                    statusName: 'Received',
                  };
                }
                return doc;
              }),
            };
          }
          return assigned;
        }),
      };
    }
    return request;
  });

  if (files && files.length > 0) {
    const file = files[0];
    if (file) {
      try {
        setLoading(true);
        const reader = new FileReader();
        reader.onload = async () => {
          const fileDataUrl = reader.result as string;
          const base64Data = fileDataUrl.split(',')[1];
          const mimeTypeSegment = fileDataUrl.split(',')[0];
          const mimeType = mimeTypeSegment.split(':')[1].split(';')[0];
          const fileExtension = mimeType.split('/')[1];
          const token = '';
          const tenantId = uploadRequest.tenantId;
          const projectId = uploadRequest.projectId;
          const projectRequestId = uploadRequest.projectRequestId;
          const projectRequestSentId = uploadRequest.projectRequestSentId;
          const peopelId = uploadRequest.peopleId;
          const documentTypeAutoId = uploadRequest.documentTypeAutoId;
          const fileext = '.' + fileExtension;

          const params = {
            token: token,
            tenantId: tenantId,
            projectId: projectId,
            projectRequestId: projectRequestId,
            fileData: base64Data,
            projectRequestSentId: projectRequestSentId,
            peopelId: peopelId,
            documentTypeAutoId: documentTypeAutoId,
            fileext: fileext,
            fileName: `${file.name.split('.')[0]}`,
          };

          const data = await UploadService.projectRequestUploadFileForStaff(params);
          if (data) {
            refetchProjectRequests();
            ShowSuccessNotification('File uploaded successfully');
          }
        };

        reader.readAsDataURL(file);
      } catch (error) {
        ShowErrorNotification('Error while uploading file', error);
      } finally {
        setLoading(false);
        setProjectRequestsApiData((prevData) =>
          // eslint-disable-next-line react-hooks/exhaustive-deps
          prevData.map((request: any) => {
            if (request.projectRequestId === requestId) {
              return {
                ...request,
                assignedList: request.assignedList.map((assigned: { documents: any[] }) => {
                  if (assigned.documents.some((doc) => doc.projectRequestSentId === uploadRow.projectRequestSentId)) {
                    return {
                      ...assigned,
                      documents: assigned.documents.map((doc) => {
                        if (
                          doc.projectRequestSentId === uploadRow.projectRequestSentId &&
                          doc.documentTypeName === uploadRequest.documentTypeName
                        ) {
                          return {
                            ...doc,
                            // Update any other necessary fields in the document here
                            statusName: 'Received',
                          };
                        }
                        return doc;
                      }),
                    };
                  }
                  return assigned;
                }),
              };
            } else {
              return request;
            }
          }),
        );
      }
    }
  }
};
