import { Button, Flex } from '@dynatrace/strato-components';
import { Page, TitleBar } from '@dynatrace/strato-components-preview/layouts';
import { Tabs, Tab } from '@dynatrace/strato-components-preview/navigation';
import { PlusIcon, RefreshIcon } from '@dynatrace/strato-icons';
import { useParams } from 'react-router-dom';
import { useRef, useState } from 'react';
import { ProjectService } from '../../../services/ProjectService';
import { RequestDetails } from '../../../types/Request';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../utils/Notifications';
import { NotesDetails } from '../../../types/Notes';
import CreateRequest from '../request/CreateRequest';
import { configRoutes } from '../../../configs/constants';
import { useProjectRequestById, useProjectWithDetailsID, useStatusByTenant } from '../../../hooks/use-minerva-data';
import { groupDocumentsByProjectRequestId, GroupedDocuments } from './project-details-utils';
import { LoadingStateComponent } from '../../../components/LoadingStateComponent';
import { ProjectPeopleRelation } from '../../../types/Project';
import { Requests } from './Requests.tsx/Requests';
import { ProjectDetails } from './ProjectDetails';
import { Colors } from '@dynatrace/strato-design-tokens';
import { Notes } from './Notes';
import { useAppInfo } from '../../../contexts/AppContext';

export interface ProjectRequestDetailsProps {}

export const ProjectRequestDetails = () => {
  const { ProjectId } = useParams();

  const [isSidebarDismissed, setIsSidebarDismissed] = useState<boolean>(false);

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [createRequestSheet, setCreateRequestSheet] = useState(false);
  const [loading, setLoading] = useState(false);
  const peopleList = useRef<ProjectPeopleRelation[]>([]);
  const notes = useRef<NotesDetails[]>([]);
  const projectRequests = useRef<RequestDetails[]>([]);
  const [projectRequestsApiData, setProjectRequestsApiData] = useState<GroupedDocuments[]>([] as GroupedDocuments[]);
  
  const { tenantId } = useAppInfo();
  // get project details
  const {
    isLoading: projectDetailsLoading,
    isRefetching: projectDetailsRefetching,
    data: projectDetailsData,
    error: projectDetailsError,
    refetch: refetchProjectDetails,
  } = useProjectWithDetailsID(Number(ProjectId));

  if (projectDetailsError) {
    ShowErrorNotification('Error loading project details', projectDetailsError);
  }

  // get project requests custom hook
  const {
    isLoading: projectRequestsLoading,
    isRefetching: projectRequestsRefetching,
    data: projectRequestsData,
    error: requestError,
    refetch: refetchProjectRequests,
  } = useProjectRequestById(Number(ProjectId));
  if (requestError) {
    ShowErrorNotification('Error loading project requests', requestError);
  }

    // get status Details
    const {
      isLoading: requestStatusLoading,
      data: requestStatusData,
      error: requestStatusError,
    } = useStatusByTenant(tenantId, 1);
  
    if (requestStatusError) {
      ShowErrorNotification('Error loading request status details', requestStatusError);
    }
  
  const loadingDetails =
  projectRequestsLoading || projectDetailsLoading || requestStatusLoading || projectDetailsRefetching || projectRequestsRefetching || loading;

  if (!loadingDetails ) {
    //notes
    projectRequests.current = projectRequestsData.projectRequest;
    peopleList.current = projectDetailsData?.projectPeopleRelation ?? [];

    const notesDesc = projectDetailsData?.notes.sort(
      (a: any, b: any) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
    );
    notes.current = notesDesc || [];

    //requests
    const projectRequestsWithDocuments = groupDocumentsByProjectRequestId(projectRequestsData.projectRequest);
    const projectRequestsWithDocumentsDesc = projectRequestsWithDocuments.sort(
      (a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
    );
    if (JSON.stringify(projectRequestsApiData) !== JSON.stringify(projectRequestsWithDocumentsDesc)) {
      setProjectRequestsApiData(projectRequestsWithDocumentsDesc);
    }
  }

  const handleCreateRequest = async (newRequest: any) => {
    setProjectRequestsApiData((prev: any) => [newRequest, ...prev]);
    refetchProjectRequests();
  };

  const CreateRequestClick = () => {
    setCurrentTabIndex(0);
    setCreateRequestSheet(true);
  };

  const handleSelectChange = async (updatedStatus: string, statusOptions: any, cell: any) => {
    // let selectedName = updatedStatus;
    let selectedValue = '';
    statusOptions.forEach((option: { statusName: string; statusAutoId: any }) => {
      if (updatedStatus === option.statusName) {
        selectedValue = option.statusAutoId;
      }
    });

    const request = {
      projectRequestSentId: cell.row.original.projectRequestSentId,
      statusAutoId: selectedValue + '',
    };

    try {
      const data: any = await ProjectService.updateProjectRequestStatus(request);
      if (data) {
        refetchProjectRequests();
        ShowSuccessNotification('Update Status Successfully ');
      }
    } catch (error) {
      ShowErrorNotification('Error updating status ', error);
    } finally {
    }
  };

  const refreshRequests = () => {
    setCurrentTabIndex(0);
    refetchProjectDetails();
    refetchProjectRequests();
  };

  const onSidebarDismissChange = () => {
    setIsSidebarDismissed(!isSidebarDismissed);
  };

  return (
    <>
      <CreateRequest
        isOpen={createRequestSheet}
        onClose={function (): void {
          setCreateRequestSheet(!createRequestSheet);
        }}
        peopleList={peopleList.current}
        projectDetails={projectDetailsData}
        onRequestCreated={handleCreateRequest}
      />
      <TitleBar>
        <TitleBar.Prefix>
          <Page.PanelControlButton target='sidebar' />
        </TitleBar.Prefix>
        <TitleBar.Title>{projectDetailsData?.project.projectName ?? ''}</TitleBar.Title>
        <TitleBar.Subtitle>{configRoutes[4].desc} </TitleBar.Subtitle>
        <TitleBar.Suffix>
          <Flex gap={8}>
            <Button
              onClick={() => CreateRequestClick()}
              variant='accent'
              color='primary'
              style={{ margin: '0px 0px 0px auto' }}
            >
              <Button.Prefix>
                <PlusIcon />
              </Button.Prefix>
              Create a request
            </Button>
            <Button variant='emphasized' color='neutral' onClick={refreshRequests}>
              <Button.Prefix>
                <RefreshIcon />
              </Button.Prefix>
              Refresh
            </Button>
          </Flex>
        </TitleBar.Suffix>
      </TitleBar>
      {loadingDetails && <LoadingStateComponent loading={true} />}
      <Page style={{ height: 'calc(100% - 65px)' }}>
        <Page.Sidebar
          dismissed={isSidebarDismissed}
         //onDismissChange={setIsSidebarDismissed}
          preferredWidth={275}
          style={{ backgroundColor: Colors.Background.Base.Default }}
        >
          <ProjectDetails
            project={projectDetailsData?.project}
            projectStatus={projectDetailsData?.status}
            projectLoan={projectDetailsData?.loanType}
            projectPeopleRelation={projectDetailsData?.projectPeopleRelation ?? []}
            projectBusinessesRelation={projectDetailsData?.projectBusinessesRelation ?? []}
            refetchProjectDetails={refetchProjectDetails}
            onSidebarDismissChange={onSidebarDismissChange}
          />
        </Page.Sidebar>
        <Page.Main>
          <Flex flexDirection={'column'}>
            <Page.PanelControlButton onClick={onSidebarDismissChange} target='sidebar' style={{ display: isSidebarDismissed ? 'block':'none',position: 'absolute', top: '15px', left: '0px' }} />
            <Tabs
              selectedIndex={currentTabIndex}
              style={{ position: 'relative', left: isSidebarDismissed ? '20px' : 0, width: isSidebarDismissed ? 'calc(100% - 20px)' : '100%' }}
              onChange={setCurrentTabIndex}
            >
              <Tab title='Requests'>
                <Requests
                  projectRequestsApiData={projectRequestsApiData}
                  requestStatusData={requestStatusData?.statuses}
                  handleSelectChange={handleSelectChange}
                  projectRequests={projectRequests.current}
                  setProjectRequestsApiData={setProjectRequestsApiData}
                  refetchProjectRequests={refetchProjectRequests}
                  setLoading={setLoading}
                />
              </Tab>
              <Tab title='Notes'>
                <Notes projectId={projectDetailsData?.project.projectId ?? 0} notes={notes.current} refetch={refetchProjectDetails} />
              </Tab>
            </Tabs>
            <Flex>&nbsp;</Flex>
          </Flex>
        </Page.Main>
      </Page>
    </>
  );
};
