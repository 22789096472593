import {
  DataTable,
  DateTimePicker,
  FormField,
  Label,
  NumberInput,
  SelectV2,
  Sheet,
  Tab,
  TableRowActions,
  Tabs,
  TextArea,
  TextInput,
  TitleBar,
  Tooltip,
  uuidv4,
} from '@dynatrace/strato-components-preview';
import { Button, Flex, TextEllipsis } from '@dynatrace/strato-components';
import { Key, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { isArray } from 'lodash';
import { ItemInfo } from '../../types/ListItemInfo';
import { useAppInfo } from '../../contexts/AppContext';
import { User } from '../../types/User';
import { ProjectService } from '../../services/ProjectService';
import { Project, ProjectBusinessRelation, ProjectDetail, ProjectPeopleRelation } from '../../types/Project';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { TenantUsers } from '../../types/Tenant';
import { BusinessService } from '../../services/BusinessService';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { Persona } from '../../types/Personas';
import { PersonaService } from '../../services/PersonaService';
import { BusinessAnalyticsSignetIcon, DeleteIcon, DescriptionIcon, GroupIcon, PlusIcon } from '@dynatrace/strato-icons';
import { groupHeaderFontStyle } from '../GeneralStyles.css';
import { NewProjectPeopleRelationModal } from './project-relations/NewProjectPeopleRelationModal';
import { NewProjectBusinessRelationModal } from './project-relations/NewProjectBusinessRelationModal';
import { PARENT_TYPE_PROJECT } from '../../types/Types';

export interface ProjectSheetProps {
  closeDetails: (itemInfo?: ItemInfo) => void;
  show: boolean;
  itemInfo?: ItemInfo;
}

export const ProjectSheet = (props: ProjectSheetProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingBusinessRelations, setLoadingBusinessRelations] = useState<boolean>(false);
  const [loadingPeopleRelations, setLoadingPeopleRelations] = useState<boolean>(false);

  const { closeDetails, show, itemInfo } = props;
  const [itemVisible, setItemVisible] = useState(show);

  const workingOnCall = useRef<boolean>(false);
  const primaryAssignedStaffList = useRef<User[]>();
  const projectDetails = useRef<any>();
  const projectBorrower = useRef<ProjectPeopleRelation>();

  const [projectPeopleRelation, setProjectPeopleRelation] = useState<ProjectPeopleRelation[]>();
  const [projectBusinessRelation, setProjectBusinessRelation] = useState<ProjectBusinessRelation[]>();

  const [showExistingPersonModal, setShowExistingPersonModal] = useState<boolean>(false);

  const [showExistingBusinessModal, setShowExistingBusinessModal] = useState<boolean>(false);

  const projectBusinessColumns = useMemo(
    () => [
      {
        id: 'businessName',
        header: 'Business Name',
        accessor: 'businessName',
        width: 300,
      },
      {
        id: 'businessType',
        header: 'Business Type',
        accessor: 'businessType',
        autoWidth: true,
      },
    ],
    [],
  );

  const projectPeopleColumns = useMemo(
    () => [
      {
        id: 'clientName',
        header: 'Name',
        accessor: 'clientName',
        width: 300,
        cell: (cell: any) => (
          <DataTable.Cell>
            <Tooltip placement='bottom' text={`${cell.row.original.firstName}, ${cell.row.original.lastName}`}>
              {
                <TextEllipsis truncationMode='end'>
                  {`${cell.row.original.lastName}, ${cell.row.original.firstName}`}
                </TextEllipsis>
              }
            </Tooltip>
          </DataTable.Cell>
        ),
      },
      {
        id: 'personaName',
        header: 'Project role',
        accessor: 'personaName',
        autoWidth: true,
      },
      {
        id: 'email',
        header: 'Email',
        accessor: 'email',
      },
    ],
    [],
  );

  const { tenantId, userId, /* tenantIndustries,*/ tenantLoanTypes, tenantPersonaList, tenantProjectStatusList } =
    useAppInfo();

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    formState: { isSubmitSuccessful },
  } = useForm<{
    projectName: string;
    projectDescription: string;
    loanAmount: number | undefined;
    assignedStaff: undefined | Key;
    staffNote: string;
    purpose: string;
    primaryBorrower: undefined | Key;
    primaryBusiness: undefined | Key;
    //MSD-68: Drop this field from the project.
    //primaryIndustry: undefined | Key;
    loanType: undefined | Key;
    startDate: string | null;
    desiredClosingDate: string | null;
    loanPhase: undefined | Key;
    projectPeople: string;
  }>({
    mode: 'all',
    defaultValues: {
      loanAmount: 0,
    },
  });

  // discarded or closed the sheet from escape key
  const dismissDetails = () => {
    closeDetails();
  };

  useEffect(() => {
    // load other data from API
    if (workingOnCall.current === false) {
      workingOnCall.current = true;
      getRequiredData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemInfo?.id, show]);

  useEffect(() => {
    if (itemInfo?.id) {
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // get all the other required data for the edit
  // mostly loading dropdown data
  const getRequiredData = async () => {
    setLoading(true);
    try {
      const staffData: any = await BusinessService.getUserDetailsByTenantId<TenantUsers>(tenantId);
      primaryAssignedStaffList.current = staffData.users;

      // if not its a new project
      if (itemInfo && itemInfo.id) {
        projectDetails.current = await ProjectService.getProjectWithDetailsID<ProjectDetail>(itemInfo?.id);
      }
    } catch (error) {
      ShowErrorNotification('Error fetching user data', error);
      setLoading(false);
    } finally {
      if (projectDetails.current) {
        // initialize values from the project
        const project: Project = projectDetails.current.project;
        setProjectPeopleRelation(projectDetails.current.projectPeopleRelation);
        setProjectBusinessRelation(projectDetails.current.projectBusinessesRelation);
        reset({
          projectName: project.projectName,
          projectDescription: project.projectDescription,
          loanAmount: parseInt(project.amount),

          assignedStaff: project.assignedToUserId,
          purpose: project.purpose,
          primaryBorrower: projectBorrower.current?.peopleId,
          primaryBusiness: projectDetails.current.projectBusinessesRelation?.[0]?.businessId,
          //MSD-68: Drop this field from the project.
          //primaryIndustry: projectDetails.current.industry?.industryId,
          loanType: projectDetails.current.project.loanTypeAutoId,
          startDate: project.projectStartDate ? new Date(project.projectStartDate).toISOString() : null,
          desiredClosingDate: project.desiredClosedDate ? new Date(project.desiredClosedDate).toISOString() : null,
          loanPhase: projectDetails.current.status.statusId,
          projectPeople: JSON.stringify(projectPeopleRelation),
        });
      } else {
        // set default values
        reset({
          // loanType: tenantLoanTypes?.[4].loanTypeId,
          startDate: new Date().toISOString(),
          desiredClosingDate: null,
          loanPhase: tenantProjectStatusList?.[0].statusId,
        });
      }
      setLoading(false);
    }
  };

  // save the information
  const saveDetails = async (values: any) => {
    //console.log('Saving project details', values);
    if (itemInfo?.id) {
      try {
        const updateParams = {
          projectId: itemInfo.id,
          tenantId: tenantId,
          projectName: values.projectName,
          projectDescription: values.projectDescription,
          //MSD-68: Drop this field from the project.
          //industryId: values.primaryIndustry,
          amount: values.loanAmount ? String(values.loanAmount) : '0',
          purpose: values.purpose,
          assignedToUserId: values.assignedStaff,
          modifiedByUserId: userId,
          modifiedDateTime: new Date().toISOString(),
          loanTypeAutoId: values.loanType,
          statusAutoId: values.loanPhase,
          projectFilesPath: values.projectFilesPath,
          projectStartDate: values.startDate,
          desiredClosedDate: values.desiredClosingDate,
          primaryBorrower: values.primaryBorrower,
          primaryBusiness: values.primaryBusiness,
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const data: any = await ProjectService.updateProject(updateParams);
        // if we already  had a borrower, first delete and then re-create.
        if (updateParams.primaryBorrower && updateParams.primaryBorrower !== projectBorrower.current?.peopleId) {
          if (projectBorrower.current?.peopleId) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const data: any = await ProjectService.deleteProjectPeopleRelationWithId(
              projectBorrower.current?.projectPeopleId,
            );
          }
          const mapClientDetails: any = [];
          mapClientDetails.push({
            projectPeopleId: 0,
            tenantId: tenantId,
            projectId: itemInfo.id,
            peopleId: updateParams.primaryBorrower,
            primaryBorrower: 1,
            personaAutoId: tenantPersonaList?.find((persona: Persona) => persona.personaName === 'Borrower')
              ?.personaAutoId,
          });
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const data: any = await PersonaService.createProjectPeopleRelation(mapClientDetails);
        }
        ShowSuccessNotification('Project updated successfully');
      } catch (error) {
        ShowErrorNotification('Error updating project', error);
        closeDetails();
        return;
      }
    } else {
      try {
        const projectBusinessesRelations = {
          businessId: values.primaryBusiness,
          primaryForLoan: values.primaryBusiness,
        };
        const updateParams = {
          tenantId: tenantId,
          projectName: values.projectName,
          projectDescription: values.projectDescription,
          //MSD-68: Drop this field from the project.
          //industryId: values.primaryIndustry,
          amount: values.loanAmount ? String(values.loanAmount) : '0',
          purpose: values.purpose,
          createdByUserId: userId,
          createdDateTime: new Date().toISOString(),
          assignedToUserId: values.assignedStaff,
          modifiedByUserId: userId,
          modifiedDateTime: new Date().toISOString(),
          loanTypeAutoId: values.loanType,
          statusAutoId: values.loanPhase ?? tenantProjectStatusList?.[0]?.statusId,
          projectFilesPath: values.projectFilesPath,
          projectStartDate: values.startDate,
          desiredClosedDate: values.desiredClosingDate,
          //notes: values.notes ?? '',
          primaryBorrower: values.primaryBorrower,
          primaryBusiness: values.primaryBusiness,
          projectBusinessesRelations: values.primaryBusiness === 0 ? [] : [projectBusinessesRelations],
          //projectPeopleRelations: values.primaryBorrower === undefined ? [] : [projectPeopleRelation],
        };
        const dataProject: any = await ProjectService.postProjectprojectWithDetails(updateParams);
        if (updateParams.primaryBorrower) {
          const mapClientDetails: any = [];
          mapClientDetails.push({
            projectPeopleId: 0,
            tenantId: tenantId,
            projectId: dataProject.projectId,
            peopleId: updateParams.primaryBorrower,
            primaryBorrower: 1,
            personaAutoId: tenantPersonaList?.find((persona: Persona) => persona.personaName === 'Borrower')
              ?.personaAutoId,
          });
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const data: any = await PersonaService.createProjectPeopleRelation(mapClientDetails);
        }

        ShowSuccessNotification('Project created successfully');
      } catch (error) {
        ShowErrorNotification('Error creating project', error);
        closeDetails();
        return;
      }
    }

    // save the information and then close
    closeDetails({ refreshParent: true });
  };

  // updates project business relations post creating a new one or deleting
  const updateProjectBusinessRelations = async () => {
    try {
      const data: any = await ProjectService.getProjectBusinessesRelationWithProjectId(itemInfo?.id ?? 0);
      setProjectBusinessRelation(data.businessRelation);
    } catch (error) {
      ShowErrorNotification('Error updating project business relation', error);
    }
    setLoadingBusinessRelations(false);
  };

  // updates project people relations post creating a new one or deleting
  const updateProjectPeopleRelations = async () => {
    try {
      const data = await ProjectService.getProjectWithDetailsID<ProjectDetail>(itemInfo?.id ?? 0);
      setProjectPeopleRelation(data.projectPeopleRelation);
    } catch (error) {
      ShowErrorNotification('Error updating project people relation', error);
    }
    setLoadingPeopleRelations(false);
  };

  const title = itemInfo?.name ? itemInfo.name : 'New Project';
  const subTitle = itemInfo?.name ? 'Updating project details' : 'Creating new project';
  return (
    <Sheet show={itemVisible} onDismiss={dismissDetails} style={{ height: '100%' }}>
      <form onSubmit={handleSubmit(saveDetails)} onReset={() => reset()} noValidate>
        <Flex flexDirection='column' margin={8} padding={0} gap={8}>
          <TitleBar>
            <TitleBar.Title> {title} </TitleBar.Title>
            <TitleBar.Subtitle> {subTitle} </TitleBar.Subtitle>
            <TitleBar.Action style={{ display: 'flex', alignItems: 'center' }}>
              <Flex flexDirection='row' gap={8}>
                <Button
                  width='75px'
                  onClick={() => {
                    setItemVisible(false);
                    dismissDetails();
                  }}
                  variant='default'
                >
                  Discard
                </Button>
                <Button width='60px' type='submit' variant='accent' color='primary' disabled={isSubmitSuccessful}>
                  Save
                </Button>
              </Flex>
            </TitleBar.Action>
          </TitleBar>
          <LoadingStateComponent loading={loading} />

          {!loading && projectDetails && (
            <Tabs defaultIndex={0}>
              <Tab
                /*prefixIcon={
                  Object.keys(errors?. ?? {})?.length > 0 && (
                  </Tabs>  <img src={errorImageAsset} alt={'Error'} height='12px' width='12px' />
                  )
                }*/
                prefixIcon={<DescriptionIcon />}
                title={'Project details'}
              >
                <Flex flexDirection='column' width={'50%'} minWidth={325} gap={16}>
                  <Controller
                    name='projectName'
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Project name is required.',
                      },
                      maxLength: {
                        value: 200,
                        message: 'Please enter a valid project name.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField required>
                        <Label>Project name</Label>
                        <TextInput
                          aria-label='Project name'
                          placeholder='Enter project name'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='projectDescription'
                    control={control}
                    rules={{
                      maxLength: {
                        value: 250,
                        message: 'Description cannot have more than 250 characters.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Description</Label>
                        <TextArea
                          {...field}
                          placeholder='Enter project description'
                          aria-label='Description'
                          width={'100%'}
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='loanAmount'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Loan amount</Label>
                        <NumberInput
                          aria-label='Loan amount'
                          placeholder='eg. 2000000'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message || 'Desired loan amount',
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Flex flexDirection='row' gap={4}>
                    <Controller
                      name='assignedStaff'
                      control={control}
                      rules={{
                        required: {
                          value: false,
                          message: 'Please choose an option.',
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormField>
                          <Label>Assigned staff</Label>
                          <Flex
                            onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                              if (event.key === 'Enter') {
                                event.preventDefault(); // Prevent form submission
                              }
                            }}
                          >
                            <SelectV2
                              aria-label='Assigned staff'
                              controlState={{
                                state: error ? 'error' : 'valid',
                                hint: isArray(error) ? error.join('\n') : error?.message,
                              }}
                              {...field}
                            >
                              <SelectV2.Trigger placeholder={'Assign staff member'} />

                              <SelectV2.Content style={{ maxWidth: '500px' }} width='max-content'>
                                {primaryAssignedStaffList.current &&
                                  primaryAssignedStaffList.current.map((staff, index) => (
                                    <SelectV2.Option key={index} value={staff.userId}>
                                      {staff.lastName + ', ' + staff.firstName}
                                    </SelectV2.Option>
                                  ))}
                              </SelectV2.Content>
                            </SelectV2>
                          </Flex>
                        </FormField>
                      )}
                    />
                  </Flex>
                  <Controller
                    name='purpose'
                    control={control}
                    rules={{
                      maxLength: {
                        value: 200,
                        message: 'Please enter a valid purpose.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Purpose</Label>
                        <TextInput
                          aria-label='Purpose'
                          placeholder='Enter purpose'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  {/*
                  //MSD-68: Drop this field from the project.
                  <Controller
                    name='primaryIndustry'
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: 'Please choose an option.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Primary industry</Label>
                        <SelectV2
                          aria-label='Primary industry'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: isArray(error) ? error.join('\n') : error?.message,
                          }}
                          {...field}
                        >
                          <SelectV2.Trigger placeholder={'Select an industry'} />

                          <SelectV2.Content style={{ maxWidth: '500px' }} width='max-content'>
                            <SelectV2.EmptyState>No industry found.</SelectV2.EmptyState>
                            <SelectV2.Filter></SelectV2.Filter>
                            {tenantIndustries &&
                              tenantIndustries.map((industry) => (
                                <SelectV2.Option key={industry.industrySectorAutoId} value={industry.industryId}>
                                  {industry.industrySector}
                                </SelectV2.Option>
                              ))}
                          </SelectV2.Content>
                        </SelectV2>
                      </FormField>
                    )}
                  />
                  */}
                  <Controller
                    name='loanType'
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: 'Please choose an option.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Loan type</Label>
                        <Flex
                          onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                            if (event.key === 'Enter') {
                              event.preventDefault(); // Prevent form submission
                            }
                          }}
                        >
                          <SelectV2
                            controlState={{
                              state: error ? 'error' : 'valid',
                              hint: isArray(error) ? error.join('\n') : error?.message,
                            }}
                            {...field}
                          >
                            <SelectV2.Trigger placeholder={'Select loan type'} />

                            <SelectV2.Content style={{ maxWidth: '500px' }} width='max-content'>
                              {tenantLoanTypes &&
                                tenantLoanTypes.map((loanType) => (
                                  <SelectV2.Option key={loanType.loanTypeAutoId} value={loanType.loanTypeId}>
                                    {loanType.loanType}
                                  </SelectV2.Option>
                                ))}
                            </SelectV2.Content>
                          </SelectV2>
                        </Flex>
                      </FormField>
                    )}
                  />
                  <Controller
                    name='startDate'
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: 'Please choose a date.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Start date</Label>
                        <DateTimePicker
                          type='date'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          style={{ width: 'max-content', minWidth: '150px' }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='desiredClosingDate'
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: 'Please choose a date.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Desired closing date</Label>
                        <DateTimePicker
                          type='date'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          {...field}
                          style={{ width: 'max-content', minWidth: '150px' }}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='loanPhase'
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: 'Please choose an option.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Loan phase</Label>
                        <Flex
                          onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                            if (event.key === 'Enter') {
                              event.preventDefault(); // Prevent form submission
                            }
                          }}
                        >
                          <SelectV2
                            controlState={{
                              state: error ? 'error' : 'valid',
                              hint: isArray(error) ? error.join('\n') : error?.message,
                            }}
                            {...field}
                          >
                            <SelectV2.Trigger placeholder={'Select loan phase'} />

                            <SelectV2.Content style={{ width: 'max-content', minWidth: '500px' }}>
                              {tenantProjectStatusList &&
                                tenantProjectStatusList.map((projectStatus, index) => (
                                  <SelectV2.Option key={index} value={projectStatus.statusId}>
                                    {projectStatus.statusName}
                                  </SelectV2.Option>
                                ))}
                            </SelectV2.Content>
                          </SelectV2>
                        </Flex>
                      </FormField>
                    )}
                  />
                </Flex>
              </Tab>
              <Tab title={'People'} prefixIcon={<GroupIcon />} disabled={itemInfo?.id === undefined}>
                <Flex flexDirection='column' width={'50%'} minWidth={325} gap={8}>
                  <Flex
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='end'
                    //marginBottom={4}
                    marginTop={8}
                  >
                    <Label className={groupHeaderFontStyle}>People associated with this project</Label>
                    <Flex>
                      <Button
                        onClick={() => {
                          setShowExistingPersonModal(true);
                        }}
                        variant='accent'
                        color='primary'
                        style={{ margin: '0px 0px 0px auto' }}
                      >
                        <Button.Prefix>
                          <PlusIcon />
                        </Button.Prefix>
                        Add person
                      </Button>
                      {showExistingPersonModal && (
                        <NewProjectPeopleRelationModal
                          key={uuidv4()}
                          parentType={PARENT_TYPE_PROJECT}
                          parentId={itemInfo?.id ?? 0}
                          currentProjectPeopleList={projectPeopleRelation ?? []}
                          currentBusinessPeopleList={[]}
                          onDismiss={(update) => {
                            setShowExistingPersonModal(false);
                            if (update) {
                              setLoadingPeopleRelations(true);
                              updateProjectPeopleRelations();
                            }
                          }}
                        />
                      )}
                    </Flex>
                  </Flex>
                  <Controller
                    name='projectPeople'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <TextArea
                          style={{ visibility: 'hidden', display: 'none' }}
                          aria-label='Description'
                          width={'100%'}
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message || 'Please enter between 10 and 250 characters.',
                          }}
                          {...field}
                        />{' '}
                      </FormField>
                    )}
                  />
                  <DataTable
                    loading={loadingPeopleRelations}
                    //sortable
                    columns={projectPeopleColumns}
                    data={projectPeopleRelation ?? []}
                    variant={{ rowSeparation: 'zebraStripes' }}
                    //sortBy={{ id: 'clientName', desc: false }}
                  >
                    <DataTable.EmptyState>No one is associated with this project.</DataTable.EmptyState>
                    <DataTable.UserActions>
                      <DataTable.RowActions>
                        {(row) => (
                          <TableRowActions.Group>
                            <TableRowActions.Item
                              onClick={async () => {
                                // deleting the buisness from the project
                                try {
                                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                  const postData: any = await ProjectService.deleteProjectPeopleRelationWithId(
                                    row.original.projectPeopleId,
                                  );
                                  setLoadingPeopleRelations(true);
                                  updateProjectPeopleRelations();
                                } catch (error) {
                                  ShowErrorNotification('Error deleting people association with the project', error);
                                }
                              }}
                              prefixIcon={<DeleteIcon />}
                            ></TableRowActions.Item>
                          </TableRowActions.Group>
                        )}
                      </DataTable.RowActions>
                    </DataTable.UserActions>
                  </DataTable>
                </Flex>
              </Tab>
              <Tab
                title={'Businesses'}
                prefixIcon={<BusinessAnalyticsSignetIcon />}
                disabled={itemInfo?.id === undefined}
              >
                <Flex flexDirection='column' width={'50%'} minWidth={325} gap={8}>
                  <Flex flexDirection='row' justifyContent='space-between' alignItems='end' marginTop={8}>
                    <Label className={groupHeaderFontStyle}>Businesses associated with this project</Label>
                    <Flex>
                      <Button
                        onClick={() => {
                          setShowExistingBusinessModal(true);
                        }}
                        variant='accent'
                        color='primary'
                        style={{ margin: '0px 0px 0px auto' }}
                      >
                        <Button.Prefix>
                          <PlusIcon />
                        </Button.Prefix>
                        Add business
                      </Button>
                      {showExistingBusinessModal && (
                        <NewProjectBusinessRelationModal
                          key={uuidv4()}
                          parentType={PARENT_TYPE_PROJECT}
                          projectId={itemInfo?.id ?? 0}
                          currentProjectBusinessList={projectBusinessRelation ?? []}
                          onDismiss={(refresh) => {
                            setShowExistingBusinessModal(false);
                            if (refresh) {
                              setLoadingBusinessRelations(true);
                              updateProjectBusinessRelations();
                            }
                          }}
                        />
                      )}
                    </Flex>
                  </Flex>
                  <DataTable
                    //sortable
                    loading={loadingBusinessRelations}
                    columns={projectBusinessColumns}
                    data={projectBusinessRelation ?? []}
                    variant={{ rowSeparation: 'zebraStripes' }}
                    //sortBy={{ id: 'businessName', desc: false }}
                  >
                    <DataTable.EmptyState>No businesses are associated with this project.</DataTable.EmptyState>
                    <DataTable.UserActions>
                      <DataTable.RowActions>
                        {(row) => (
                          <TableRowActions.Group>
                            <TableRowActions.Item
                              onClick={async () => {
                                // deleting the buisness from the project
                                try {
                                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                  const postData: any = await ProjectService.deleteProjectBusinessesRelationWithId(
                                    row.original.projectBusinessId,
                                  );
                                  setLoadingBusinessRelations(true);
                                  ShowSuccessNotification('Business association with the project deleted successfully');
                                  updateProjectBusinessRelations();
                                } catch (error) {
                                  ShowErrorNotification('Error deleting business association with the project', error);
                                }
                              }}
                              prefixIcon={<DeleteIcon />}
                            ></TableRowActions.Item>
                          </TableRowActions.Group>
                        )}
                      </DataTable.RowActions>
                    </DataTable.UserActions>
                  </DataTable>
                </Flex>
              </Tab>
            </Tabs>
          )}
        </Flex>
      </form>
    </Sheet>
  );
};
