import { Tooltip } from '@dynatrace/strato-components-preview/overlays';
import { DataTable, TableRowActions } from '@dynatrace/strato-components-preview/tables';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { DownloadIcon, UploadIcon } from '@dynatrace/strato-icons';
import { columns, getRowDocumentColumns, rowColumns } from './table-definitions';
import { Dispatch, useRef } from 'react';
import { RequestDetails } from '../../../../types/Request';
import { ProjectService } from '../../../../services/ProjectService';
import { GroupedDocuments, handleFileChange } from '../project-details-utils';
import { hideElement } from '../../ProjectStyles.css';
import { StatusDetails } from '../../../../types/Status';

export interface RequestsProps {
  projectRequestsApiData: any;
  requestStatusData: StatusDetails[];
  handleSelectChange: ((updatedStatus: string, statusOptions: any, cell: any) => void);
  projectRequests: RequestDetails[];
  setProjectRequestsApiData: Dispatch<React.SetStateAction<GroupedDocuments[]>>,
  refetchProjectRequests: ()=> void,
  setLoading: Dispatch<React.SetStateAction<boolean>>,

}

export const Requests = (props: RequestsProps) => {
  const {
    projectRequestsApiData,
    requestStatusData,
    projectRequests,
    handleSelectChange,
    setProjectRequestsApiData,
    refetchProjectRequests,
    setLoading,
  } = props;

  const fileInputRefs = useRef<{ [key: number]: (HTMLInputElement | null)[] }>({});

  const downloadFileClick = function (rowInfo: any) {
    //In Local, uncomment the below line
    // window.open(ProjectService.getDownloadFileHeader(rowInfo.original.downloadFilePath));
    //In Dev, uncomment the below Line
    window.open(ProjectService.getDownloadFileHeader(rowInfo.downloadFilePath));
  };

  const handleUploadClick = (requestId: number, projectRequestSentId: number, documentIndex: number) => {
    if (fileInputRefs.current[requestId] && fileInputRefs.current[requestId][documentIndex]) {
      fileInputRefs.current[requestId][documentIndex]!.click();
    }
  };

  return (
    <Flex flexDirection='column' style={{ overflow: 'auto' }}>
      {projectRequestsApiData !== undefined && (
        <DataTable
          columns={columns as any}
          data={projectRequestsApiData}
          sortable
          variant={{ rowDensity: 'default' }}
          sortBy={{ id: 'createdOn', desc: true }}
          style={{ overflowY: 'auto' }}
        >
          <DataTable.ExpandableRow>
            {({ row }) => {
              const requestId = row.projectRequestId;
              // const projectRequestSentId = row.projectRequestSentId;
              if (!fileInputRefs.current[requestId]) {
                fileInputRefs.current[requestId] = [];
              }
              return (
                <Flex flexDirection='column'>
                  <DataTable
                    columns={rowColumns as any}
                    data={row.assignedList}
                    variant={{ rowDensity: 'default', contained: false }}
                  >
                    <DataTable.ExpandableRow>
                      {(assignedRow) => {
                        // const assignedIndex = Number(assignedRow.row.index);
                        return (
                          <Flex flexDirection='column'>
                            <DataTable
                              columns={getRowDocumentColumns(requestStatusData, handleSelectChange) as any}
                              data={assignedRow.row.documents}
                              variant={{ rowDensity: 'default', contained: false }}
                            >
                              <DataTable.UserActions>
                                <DataTable.RowActions>
                                  {(documentRow) => {
                                    const documentIndex = Number(documentRow.index);
                                    //In Local use the below line
                                    // const requestRow: any = documentRow.original.original;

                                    //In Dev use the below line
                                    const requestRow = documentRow.original;
                                    if (!fileInputRefs.current[requestId][documentIndex]) {
                                      fileInputRefs.current[requestId][documentIndex] = null;
                                    }
                                    return (
                                      <TableRowActions.Group>
                                        <input
                                          type='file'
                                          ref={(el) => (fileInputRefs.current[requestId][documentIndex] = el)}
                                          className={hideElement}
                                          onChange={(event) =>
                                            handleFileChange(
                                              event,
                                              requestId,
                                              requestRow,
                                              documentIndex,
                                              projectRequests,
                                              projectRequestsApiData,
                                              setProjectRequestsApiData,
                                              refetchProjectRequests,
                                              setLoading,
                                            )
                                          }
                                          title='Upload file'
                                        />
                                        <Tooltip text={'Upload Document'}>
                                          <Button
                                            onClick={(requestRow: any) =>
                                              handleUploadClick(requestId, requestRow as any, documentIndex)
                                            }
                                            disabled={requestRow.statusName === 'Approved'}
                                          >
                                            <Button.Prefix>
                                              <UploadIcon />
                                            </Button.Prefix>
                                          </Button>
                                        </Tooltip>
                                        <Tooltip text={'Download Document'}>
                                          <Button
                                            onClick={() => {
                                              // const item = documentRow.original;
                                              downloadFileClick(documentRow.original);
                                            }}
                                            disabled={requestRow.statusName === 'New' || requestRow.statusName === 'Rejected'}
                                          >
                                            <Button.Prefix>
                                              <DownloadIcon />
                                            </Button.Prefix>
                                          </Button>
                                        </Tooltip>
                                      </TableRowActions.Group>
                                    );
                                  }}
                                </DataTable.RowActions>
                              </DataTable.UserActions>
                            </DataTable>
                          </Flex>
                        );
                      }}
                    </DataTable.ExpandableRow>
                  </DataTable>
                </Flex>
              );
            }}
          </DataTable.ExpandableRow>
        </DataTable>
      )}
    </Flex>
  );
};
