import { FormField, Label, SelectV2, TextArea, TextInput } from '@dynatrace/strato-components-preview/forms';
import { Tooltip } from '@dynatrace/strato-components-preview/overlays';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { Borders, Colors } from '@dynatrace/strato-design-tokens';
import { DeleteIcon } from '@dynatrace/strato-icons';
import { Controller } from 'react-hook-form';
import { handleBrowseClick } from './utils';
import { hideElement } from '../ProjectStyles.css';
import { Chip } from '@dynatrace/strato-components-preview/content';

export interface RequestMessageProps {
  control: any;
  handleTemplateChange: (value: any) => void;
  requestTemplateData: any;
  fields: Record<'id', string>[];
  selectedFiles: File[][];
  handleFileRemove: (file: File, index: number) => void;
  handleAddMore: () => void;
  fileInputRefs: React.MutableRefObject<HTMLInputElement[]>;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  removeFileSection: (index: number) => void;
  remove: (index: number) => void;
}

export const RequestMessage = (props: RequestMessageProps) => {
  const {
    control,
    handleTemplateChange,
    requestTemplateData,
    fields,
    selectedFiles,
    handleFileRemove,
    handleAddMore,
    fileInputRefs,
    handleFileChange,
    removeFileSection,
    remove,
  } = props;

  return (
    <Flex flexDirection='column' gap={16}>
      <Flex flexDirection='row' width={'80%'}>
        <Controller
          name='template'
          control={control}
          rules={{
            required: {
              value: false,
              message: 'Please choose an option.',
            },
          }}
          render={({ field }) => (
            <FormField>
              <Label>Select from a template (optional)</Label>
              <Flex
                onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                  if (event.key === 'Enter') {
                    event.preventDefault(); // Prevent form submission
                  }
                }}
              >
                <SelectV2
                  {...field}
                  // onChange={(value) => onDocumentTemplateChange('template', value)}
                  onChange={(value) => {
                    field.onChange(value);
                    handleTemplateChange(value);
                  }}
                >
                  <SelectV2.Trigger placeholder={'Select a template'} />
                  <SelectV2.Content width={'auto'}>
                    {requestTemplateData?.map(
                      (option: {
                        requestTemplateId: React.Key | null | undefined;
                        requestTemplateName:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                          | Iterable<React.ReactNode>
                          | React.ReactPortal
                          | null
                          | undefined;
                      }) => (
                        <SelectV2.Option key={option.requestTemplateId} value={option.requestTemplateId}>
                          {option.requestTemplateName}
                        </SelectV2.Option>
                      ),
                    )}
                  </SelectV2.Content>
                </SelectV2>
              </Flex>
            </FormField>
          )}
        />
      </Flex>
      <Flex flexDirection='column' gap={8}>
        <Controller
          name='requestName'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Request description is required',
            },
            minLength: {
              value: 1,
              message: '',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormField required>
              <Label>Request description</Label>
              <TextInput
                {...field}
                value={field.value}
                style={{ width: '80%' }}
                placeholder='Enter your request description'
                // onChange={(value: any) => handleChange('requestName', value)}

                controlState={{
                  state: error ? 'error' : 'valid',
                  hint: error?.message || '',
                }}
              />
            </FormField>
          )}
        />
        <Controller
          name='requestDescription'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Description is required',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormField required>
              <Label>Request message</Label>
              <TextArea
                {...field}
                value={field.value}
                placeholder='Enter your message'
                // onChange={(value: any) => handleChange('requestDescription', value)}
                width={'80%'}
                controlState={{
                  state: error ? 'error' : 'valid',
                }}
              />
            </FormField>
          )}
        />
      </Flex>

      {fields.map((field, index) => (
        <Flex
          key={field.id}
          flexDirection='row'
          style={{
            border: `1px solid ${Colors.Border.Neutral.Default}`,
            borderRadius: Borders.Radius.Field.Default,
            width: '80%',
          }}
          gap={16}
        >
          <Flex flexDirection='column' padding={12} gap={8} width={'100%'}>
            <Controller
              name={`requestDetails.${index}.label`}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'File name is required',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <FormField required>
                  <Label>File name</Label>
                  <TextInput
                    {...field}
                    value={field.value}
                    placeholder='Enter file name'
                    controlState={{
                      state: error ? 'error' : 'valid',
                      hint: error?.message || '',
                    }}
                  />
                </FormField>
              )}
            />
            <Controller
              name={`requestDetails.${index}.instructions`}
              control={control}
              // rules={{
              //   required: {
              //     value: true,
              //     message: 'Instruction is required',
              //   },
              // }}
              render={({ field, fieldState: { error } }) => (
                <FormField>
                  <Label>Instructions</Label>
                  <TextArea
                    {...field}
                    value={field.value}
                    width={'100%'}
                    placeholder='Enter instructions'
                    controlState={{
                      state: error ? 'error' : 'valid',
                      hint: error?.message || '',
                    }}
                  />
                </FormField>
              )}
            />
            {/* <Controller
                        name={`requestDetails.${index}.documentTypeAutoId`}
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Please choose an option.',
                          },
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <FormField required>
                            <Label>File type</Label>
                            <Flex
                              onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                                if (event.key === 'Enter') {
                                  event.preventDefault(); // Prevent form submission
                                }
                              }}
                            >
                              <SelectV2
                                {...field}
                                value={field.value}
                                controlState={{
                                  state: error ? 'error' : 'valid',
                                  hint: error?.message || '',
                                }}
                              >
                                <SelectV2.Content width={'auto'}>
                                  {documentTypes.current.map((option) => (
                                    <SelectV2.Option key={option.documentTypeAutoId} value={option.documentTypeAutoId}>
                                      {option.documentTypeName}
                                    </SelectV2.Option>
                                  ))}
                                </SelectV2.Content>
                              </SelectV2>
                            </Flex>
                          </FormField>
                        )}
                      /> */}
            <Flex flexDirection='column'>
              {selectedFiles.length > 0 && (
                <Flex flexDirection='column' rowGap={8}>
                  {selectedFiles[index] && selectedFiles[index].length > 0 && (
                    <Flex flexDirection='row' rowGap={8} flexWrap='wrap'>
                      {selectedFiles[index]?.map((file) => (
                        <Tooltip text={file.name}>
                          <Chip variant='emphasized' color='primary'>
                            {`${file.name} (${(file.size / 1024).toFixed(2)} kB)`}
                            <Chip.DeleteButton onClick={() => handleFileRemove(file, index)} />
                          </Chip>
                        </Tooltip>
                      ))}
                    </Flex>
                  )}
                </Flex>
              )}

              {/* Render selected files for the current index */}
              <Button variant='emphasized' color='primary' onClick={() => handleBrowseClick(fileInputRefs, index)}>
                Attach Files
              </Button>

              <input
                type='file'
                ref={(el) => (fileInputRefs.current[index] = el!)} // Use refs for each input
                className={hideElement}
                onChange={(event) => handleFileChange(event, index)}
                multiple // Allow multiple file selection
                title='Attach files'
              />
            </Flex>
          </Flex>
          {index !== 0 && (
            <Flex flexDirection={'column'} width={50} justifyContent='flex-start' alignItems='center' padding={16}>
              <Tooltip text={'Delete Document'}>
                <Button
                  variant='emphasized'
                  aria-label='Remove'
                  style={{ textAlign: 'right', visibility: index > 0 ? 'visible' : 'hidden' }}
                  onClick={() => {
                    remove(index);
                    removeFileSection(index);
                  }}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </Flex>
          )}
        </Flex>
      ))}
      <Flex flexDirection='column' gap={8}>
        <Button style={{ color: Colors.Text.Primary.Default }} onClick={handleAddMore}>
          Add more
        </Button>
      </Flex>
    </Flex>
  );
};
