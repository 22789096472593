import { Dispatch, SetStateAction } from 'react';
import { Request, UploadRequestDetails } from '../../types/Request';
import { FileFormat, FileNameState } from '../../types/File';
import { UploadService } from '../../services/UploadService';

export interface UploadFileKeys {
  token: string;
  fileFormat: FileFormat[] | undefined;
  projectId: number | undefined;
  peopleId: number | undefined;
  request: Request | undefined;
  tenantId: number | undefined;
}

export function handleUploadFileChange(
  event: React.ChangeEvent<HTMLInputElement>,
  requestDetails: UploadRequestDetails,
  setFileDataMap: Dispatch<
    SetStateAction<{
      [key: string]: {
        files: File[];
        details: any;
      };
    }>
  >,
  setFileName: Dispatch<SetStateAction<FileNameState>>,
) {
  const files = event.target.files;
  if (files) {
    const fileURL = URL.createObjectURL(files[files.length - 1]);
    const fileArray = Array.from(files);
    const newFileName = fileArray[fileArray.length - 1].name; // Get the name of the last selected file
    const newFileSize = fileArray[fileArray.length - 1].size;
    // Update fileDataMap state
    setFileDataMap((prevMap) => ({
      ...prevMap,
      [requestDetails.documentTypeAutoId]: {
        files: [...(prevMap[requestDetails.documentTypeAutoId]?.files || []), ...fileArray],
        details: requestDetails,
      },
    }));

    // Update fileName state
    setFileName((prevFiles) => ({
      ...prevFiles,
      [requestDetails.projectRequestSentId]: {
        name: newFileName,
        file: fileURL,
        size: newFileSize,
      },
    }));
  }
}

export const handleUploadFileRemove = (
  documentTypeAutoId: number,
  projectRequestSentId: number,
  setFileDataMap: Dispatch<
    SetStateAction<{
      [key: string]: {
        files: File[];
        details: any;
      };
    }>
  >,
  setFileName: Dispatch<SetStateAction<FileNameState>>,
) => {
  setFileDataMap((prevFileDataMap) => {
    const newFileDataMap = {...prevFileDataMap};
    delete newFileDataMap[documentTypeAutoId];
    return newFileDataMap;
  });

  setFileName((prevFileName) => {
    const updatedFileNameState = { ...prevFileName };

    // Remove the file data from fileName state
    if (updatedFileNameState[projectRequestSentId]) {
      delete updatedFileNameState[projectRequestSentId];
    }

    return updatedFileNameState;
  });
};

const getFileDataUrl = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result as string);
      } else {
        reject(new Error('Failed to read file'));
      }
    };
    reader.onerror = () => reject(new Error('Error reading file'));
    reader.readAsDataURL(file);
  });
};

export const blobToData = async (
  file: File,
  index: any,
  projectRequest: any,
  tenantId: any,
  projectId: any,
  peopelId: any,
  fileFormt: any,
  token: any,
  details: any,
) => {
  const fileDataUrl = await getFileDataUrl(file);
  const base64Data = fileDataUrl.split(',')[1];
  // const mimeTypeSegment = fileDataUrl.split(',')[0];
  // const mimeType = mimeTypeSegment.split(':')[1].split(';')[0];
  const fileext = file.name.split('.')[1];
  // const fileext = fileFormt[0]?.fileTypeName;

  const params = {
    token,
    tenantId,
    projectId,
    projectRequestId: projectRequest?.projectRequestId,
    projectRequestSentId: details.projectRequestSentId,
    peopelId,
    documentTypeAutoId: details.documentTypeAutoId,
    fileData: base64Data,
    fileext: '.' + fileext,
    fileName: `${file.name.split('.')[0]}`,
  };

  await UploadService.postProjectRequestUploadFiles(params);
};
