import { Label } from '@dynatrace/strato-components-preview/forms';
import { Flex } from '@dynatrace/strato-components/layouts';
import React from 'react';
import { Text } from '@dynatrace/strato-components/typography';
import { Borders, Colors } from '@dynatrace/strato-design-tokens';

export interface RequestSummaryProps {
  formValues: any;
  formData: {
    recipients: any[];
  };
  selectedFiles: File[][];
}

export const RequestSummary = (props: RequestSummaryProps) => {
  const { formValues, formData, selectedFiles } = props;

  return (
    <Flex flexDirection='column' gap={0}>
      <h3>Review Request:</h3>
      <Flex flexDirection='column' gap={4}>
        <Flex flexDirection='row'>
            <Label>Request description:</Label>
            <Text>{formValues.requestName}</Text>
        </Flex>
        <Flex flexDirection='row'>
            <Label>Request message:</Label>
            <Text>{formValues.requestDescription}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection='column' columnGap={0}>
        <h3>Receipents:</h3>
        <Flex flexDirection='row' gap={8}>
          <Flex flexDirection='column' columnGap={0}>
            <Label>Name</Label>
            {formData.recipients.map((recipient, index) => (
              <Text key={'all-recipeient-' + index}>
                {recipient.firstName} {recipient.lastName}
              </Text>
            ))}
          </Flex>
          <Flex flexDirection='column' columnGap={0}>
            <Label>Email</Label>
            {formData.recipients.map((recipient, index) => (
              <Text key={'recipeient-' + index}>{recipient.email}</Text>
            ))}
          </Flex>
        </Flex>
      </Flex>

      <Flex flexDirection='column' gap={8}>
        <h3>List of requested documents:</h3>
        {formValues?.requestDetails?.map(
          (
            section: {
              label:
                | string
                | number
                | boolean
                | React.ReactPortal
                | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                | Iterable<React.ReactNode>
                | null
                | undefined;
              instructions:
                | string
                | number
                | boolean
                | React.ReactPortal
                | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                | Iterable<React.ReactNode>
                | null
                | undefined;
            },
            index: number,
          ) => (
            <Flex
              width={'80%'}
              key={index}
              flexDirection='column'
              padding={8}
              gap={8}
              style={{
                border: `1px solid ${Colors.Border.Neutral.Default}`,
                borderRadius: Borders.Radius.Field.Default,
              }}
            >
              <Flex flexDirection='row' gap={8}>
                <Label>Document Id no:</Label>
                <Text>#{index + 1}</Text>
              </Flex>
              <Flex flexDirection='row' gap={8}>
                <Label>File name:</Label>
                <Text>{section.label}</Text>
              </Flex>
              <Flex flexDirection='row' gap={8}>
                {/* <p>
              <Label>File Type:</Label>
            </p>
            <p>{getDocumentTypeName(section?.documentTypeAutoId)}</p> 
            */}
                <Label>File instructions:</Label>
                <Text>{section.instructions}</Text>
              </Flex>
              <Flex flexDirection='row' gap={8}>
                <Label>Attached file(s):</Label>
                {selectedFiles[index]?.map((file, index) => (
                  <Label>
                    {file.name + (index !== selectedFiles.length - 1 && selectedFiles.length > 0 ? ', ' : '')}
                  </Label>
                ))}
              </Flex>
            </Flex>
          ),
        )}
      </Flex>
    </Flex>
  );
};
