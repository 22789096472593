import React, { useEffect, useRef, useState } from 'react';
import { Modal, FormFieldMessages, Tabs, Tab, Sheet, TitleBar } from '@dynatrace/strato-components-preview';
import { Button, Flex, Text } from '@dynatrace/strato-components';
import { Colors } from '@dynatrace/strato-design-tokens';
import { useForm, useWatch, useFieldArray } from 'react-hook-form';
import { RequestIcon, MailIcon, SourceIcon, CriticalIcon } from '@dynatrace/strato-icons';
import { ProjectService } from '../../../services/ProjectService';
import { ProjectDetail, ProjectPeopleRelation } from '../../../types/Project';
import { RequestDetails } from '../../../types/Request';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../utils/Notifications';
import { LoadingStateComponent } from '../../../components/LoadingStateComponent';
import { ItemInfo } from '../../../types/ListItemInfo';
import { useAppInfo } from '../../../contexts/AppContext';
import { TemplateService } from '../../../services/TemplateService';
import styled from 'styled-components';
import { RequestMessage } from './RequestMessage';
import { RequestSummary } from './RequestSummary';
import { RequestRecipients } from './RequestRecipients';
import { useRequestTemplates } from '../../../hooks/use-minerva-data';

const VerticalLine = styled.div`
  border-left: 2px solid ${Colors.Border.Neutral.Default};
  margin: 0 8px;
`;

export interface CreateRequestProps {
  peopleList: ProjectPeopleRelation[];
  projectDetails: ProjectDetail | any;
  isOpen: boolean;
  onClose: () => void;
  onRequestCreated: (newRequest: RequestDetails) => void;
}

export const CreateRequest = (props: CreateRequestProps) => {
  const { isOpen, onClose, peopleList, projectDetails, onRequestCreated } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const [selectedUsers, setSelectedUsers] = useState<any[]>();
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
  const [sendConfirmModal, setSendConfirmModal] = useState<boolean>(false);
  const [recipients, setRecipients] = useState<ProjectPeopleRelation[]>(peopleList);

  // use refs for hooks
  const [activeTab, setActiveTab] = useState('Request');
  const [selectedFiles, setSelectedFiles] = useState<File[][]>([[]]); // Array of file arrays for each section
  const fileInputRefs = useRef<HTMLInputElement[]>([]); // Array of file input refs

  const {
    tenantId,
    documentTypes,
    tenantRequestReminders: reminderListData,
    tenantRequestStatusList: requestStatusList,
  } = useAppInfo();

  const otherDocumentTypeId = documentTypes?.find(
    (docType) => docType.documentTypeId === -1 && docType.tenantId === tenantId,
  )?.documentTypeAutoId;

  const {
    isLoading: isTemplatesLoading,
    data: requestTemplateData,
    refetch: refetchRequestTemplates,
    isRefetching,
  } = useRequestTemplates(tenantId);

  const defaultRequestStatusId = requestStatusList?.find((status) => status.statusName === 'New')?.statusAutoId;

  useEffect(() => {
    setRecipients(peopleList);
  }, [peopleList]);

  const {
    handleSubmit,
    control,
    reset,

    formState: { errors },
  } = useForm<{
    template: string;
    requestName: string;
    requestDescription: string;
    requestDetails: RequestDetails[] | any;
  }>({
    reValidateMode: 'onChange',
    defaultValues: {
      requestDetails: [
        {
          label: '',
          documentTypeAutoId: otherDocumentTypeId,
          instructions: '',
        },
      ],
    },
    mode: undefined,
  });

  let createRequestErrors = errors;
  const formValues = useWatch({
    control,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'requestDetails',
  });

  const [formData, setFormData] = useState({
    template: 0,
    requestName: '',
    requestDescription: '',
    requestDetails: [
      {
        requestTemplateDetailsId: 0,
        requestTemplateId: 0,
        tenantId: 0,
        label: '',
        documentTypeAutoId: otherDocumentTypeId,
        documentTypeName: '',
      },
    ],

    reminder: '',
    recipients: [
      {
        email: '',
        firstName: '',
        lastName: '',
        peopleId: '',
      },
    ],
  });

  const findSize = (base64: string) => {
    // Calculate the padding
    const padding = (base64.match(/=*$/) || [])[0]?.length;

    // Calculate the size in bytes
    const fileSizeInBytes = (base64.length * 3) / 4 - (padding ? padding : 0);
    return fileSizeInBytes;
  };

  // updates project people relations post creating a new one or deleting
  const updateProjectPeopleRelations = async (addPersonsVisible: ItemInfo) => {
    try {
      setLoading(true);
      const data = await ProjectService.getProjectWithDetailsID<ProjectDetail>(addPersonsVisible?.id ?? 0);
      setRecipients(data.projectPeopleRelation);
    } catch (error) {
      ShowErrorNotification('Error updating project people relation', error);
    } finally {
      // setLoadingPeopleRelations(false);
      setLoading(false);
    }
  };

  const handleTemplateChange = async (value: any) => {
    try {
      const template = requestTemplateData?.find((temp: any) => temp.requestTemplateId === value);
      if (template) {
        const { requestTemplateName, requestTemplateDescription, requestTemplateDetails } = template;

        reset({
          template: value,
          requestName: requestTemplateName,
          requestDescription: requestTemplateDescription,
          requestDetails: requestTemplateDetails.map((detail: any) => ({
            label: detail.label,
            instructions: detail.instructions,
            documentTypeAutoId: detail.documentTypeAutoId,
          })),
        });

        template.requestTemplateDetails.forEach((details: any, index: number) => {
          // Update instructions if they exist
          if (details.instructions) {
            //console.log(`Updating instructions for index ${index}:`, details.instructions);
            // Handle the logic to update the instruction in your UI here
          }

          // Check if attachedFiles exist
          if (details.requestTemplateDetailsFiles && details.requestTemplateDetailsFiles.length > 0) {
            const attachedFiles = details.requestTemplateDetailsFiles.map((file: any) => ({
              name: file.filePath.split(details.requestTemplateDetailsId + '/')[1],
              size: findSize(file.fileData),
              type: file.type,
              base64String: file.fileData,
            }));
            setSelectedFiles((prevSelectedFiles) => {
              const updatedSelectedFiles = [...prevSelectedFiles];
              updatedSelectedFiles[index] = attachedFiles;
              return updatedSelectedFiles;
            });
          }
        });
      }
    } catch (error) {
      ShowErrorNotification('Error loading template data', error);
    }
  };

  const handleRecipientsChange = (
    selectedRows: Record<string, boolean>,
    selectedRowsData?: any[],
    trigger?: 'user' | 'internal',
  ) => {
    setSelectedRows(selectedRows);
    // Map the selected indices to the actual row data
    const updatedRecipients = selectedRowsData?.map((row) => ({
      email: row.email,
      firstName: row.firstName,
      lastName: row.lastName,
      peopleId: row.peopleId,
    }));
    setSelectedUsers(selectedRowsData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      recipients: updatedRecipients ?? [],
    }));
    setValidationErrors({});
  };

  const validateCreateRequestTab = (values: any) => {
    const errors: { [key: string]: string } = {};
    if (!values.requestName) {
      errors['Request description'] = 'Request description is required';
    }
    if (!values.requestDescription) {
      errors['Request message'] = 'Request message is required';
    } //
    values.requestDetails.map((document: any) => {
      if (!document.label) {
        errors['Document Name'] = 'File name is required';
      }
      if (!document.documentTypeAutoId) {
        errors['Document Type'] = 'File type is required';
      }
      return null;
    });

    return errors;
  };

  const validateAddRecipientsTab = () => {
    const errors: { [key: string]: string } = {};
    if (formData?.recipients.length === 0 || formData?.recipients[0].email === '') {
      errors['recipients'] = 'Atleast one recipient must be selected';
    }

    return errors;
  };

  const [tabOptions, setTabOptions] = useState({
    'Request': false,
    'Recipients': true,
    'Request overview': true,
  });

  const handleCreateRequestNextButtonClick = (values: any) => {
    if (activeTab === 'Request') {
      // saveDetails(data);
      const errors = validateCreateRequestTab(values);
      if (Object.keys(errors).length === 0) {
        setValidationErrors({});
        enableNextTab();
      } else {
        setValidationErrors(errors);
      }
    }
  };
  const handleNextButtonClick = () => {
    if (activeTab === 'Recipients') {
      const errors = validateAddRecipientsTab();
      if (Object.keys(errors).length === 0) {
        setValidationErrors({});
        enableNextTab();
      } else {
        setValidationErrors(errors);
      }
    }
  };

  const enableNextTab = () => {
    const nextIndex = (currentTabIndex + 1) % tabs.length;
    setTabOptions((prevOptions) => ({
      ...prevOptions,
      [tabs[nextIndex]]: false,
    }));
    setCurrentTabIndex(nextIndex);
    setActiveTab(tabs[nextIndex]);
  };
  const disablePrevTab = () => {
    // const nextIndex = currentTabIndex % tabs.length;
    const prevIndex = (currentTabIndex - 1) % tabs.length;
    setCurrentTabIndex(prevIndex);
    setActiveTab(tabs[prevIndex]);
  };
  // const [reminderList, setReminderList] = useState<ReminderDetails[]>([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const tabs = ['Request', 'Recipients', 'Request overview'];

  const handleAddMore = () => {
    append({
      documentTypeName: '',
      label: '',
      documentTypeAutoId: otherDocumentTypeId,
      instructions: '',
      tnantId: tenantId,
    });
    setSelectedFiles((prevFiles) => [...prevFiles, []]); // Add an empty array for new section files
  };

  // const getDocumentTypeName = (documentTypeAutoId: any) => {
  //   return documentTypes.current.find((docType) => docType.documentTypeAutoId === documentTypeAutoId)?.documentTypeName;
  // };

  // save the information
  const saveDetails = async (values: any) => {
    const { template, requestName, requestDescription, requestDetails } = values;

    const base64FilesPromises = selectedFiles.map((fileList) =>
      Promise.all(
        fileList.map(async (file: any) => {
          // if this is already read base64String then just use it as this file is not available
          // on this machine or not readable
          const base64 = file.base64String ? file.base64String : await getBase64(file);
          return {
            name: file.name,
            size: file.size,
            type: file.type,
            base64String: base64, // base64 encoded string
          };
        }),
      ),
    );

    const base64Files = await Promise.all(base64FilesPromises);

    setFormData((prevData) => ({
      ...prevData,
      template: template || prevData.template,
      requestName: requestName || prevData.requestName,
      requestDescription: requestDescription || prevData.requestDescription,
      requestDetails: requestDetails.map((doc: RequestDetails, index: number) => ({
        ...prevData.requestDetails[index],
        label: doc.label,
        documentTypeAutoId: doc.documentTypeAutoId,
        instructions: doc.instructions,
        attachedFiles: base64Files[index] || [],
      })),
    }));
    handleCreateRequestNextButtonClick(values);
  };

  const handleDismiss = () => {
    // Resetting additional form data state if needed
    setFormData({
      template: 0,
      requestName: '',
      requestDescription: '',
      requestDetails: [
        {
          requestTemplateDetailsId: 0,
          requestTemplateId: 0,
          tenantId: 0,
          label: '',
          documentTypeAutoId: otherDocumentTypeId,
          documentTypeName: '',
        },
      ],
      reminder: '0',
      recipients: [
        {
          email: '',
          firstName: '',
          lastName: '',
          peopleId: '',
        },
      ],
    });
    reset({
      template: '',
      requestName: '',
      requestDescription: '',
      requestDetails: [
        {
          label: '',
          documentTypeAutoId: otherDocumentTypeId,
          instructions: '',
        },
      ],
    });
    fileInputRefs.current = [];
    setSelectedRows({});
    // Reset the active tab to the first tab
    setCurrentTabIndex(0);
    setActiveTab('Request');
    setValidationErrors({});
    // Optionally, reset tab options if needed
    setTabOptions({
      'Request': false,
      'Recipients': true,
      'Request overview': true,
    });
    setSelectedFiles([[]]);
    onClose();
  };

  // Function to handle tab change
  const handleTabChange = (index: number) => {
    // Implement any logic needed before switching tabs, such as validation
    const isValid = true;
    if (isValid) {
      setCurrentTabIndex(index);
      setTabOptions((prevOptions) => ({
        ...prevOptions,
        [Object.keys(tabOptions)[index]]: false,
      }));
      const tabKeys = Object.keys(tabOptions);
      const tabKeyToIndex = tabKeys.reduce((acc, key, index) => {
        acc[index] = key;
        return acc;
      }, {} as { [key: number]: string });
      setActiveTab(tabKeyToIndex[index]);
    } else {
      // Handle case when validation fails
      // console.warn('Validation failed, cannot change tab.');
    }
  };

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSaveTemplate = handleSubmit(async (values) => {
    const { requestName, requestDescription, requestDetails } = values;

    const base64FilesPromises = selectedFiles.map((fileList) =>
      Promise.all(
        fileList.map(async (file: any) => {
          // if this is already read base64String then just use it as this file is not available
          // on this machine or not readable
          const base64 = file.base64String
            ? file.base64String
            : file.base64String
            ? file.base64String
            : await getBase64(file);
          return {
            name: file.name,
            size: file.size,
            type: file.type,
            base64String: base64, // base64 encoded string
          };
        }),
      ),
    );

    const base64Files = await Promise.all(base64FilesPromises);

    let formInit: any = {
      requestTemplateId: 0,
      requestTemplateName: '',
      requestTemplateDescription: '',
      remindersAutoId: '',
      requestTemplateDetails: [
        {
          documentTypeAutoId: otherDocumentTypeId,
          label: '',
          instructions: '',
          attachedFiles: [],
        },
      ],
    };

    formInit.requestTemplateDetails = requestDetails.map((rd: any, index: number) => ({
      label: rd.label,
      instructions: rd.instructions,
      documentTypeAutoId: rd.documentTypeAutoId,
      attachedFiles: base64Files[index] || [], // Attach base64 files
    }));

    formInit.requestTemplateName = requestName;
    formInit.requestTemplateDescription = requestDescription;
    formInit.remindersAutoId = reminderListData?.[0].remindersAutoId.toString();
    // formInit.requestTemplateDetails = requestDetails;

    try {
      const errors = validateCreateRequestTab(values);
      if (Object.keys(errors).length === 0) {
        setValidationErrors({});
      } else {
        setValidationErrors(errors);
      }
      // handleCreateRequestNextButtonClick(values);
      if (Object.keys(errors).length === 0) {
        try {
          setLoading(true);

          const data: any = await TemplateService.postRequestTemplates(formInit);
          if (data.code === 500) {
            ShowErrorNotification('Error creating template', data.message);
            refetchRequestTemplates();

            // onTemplateClose(tenantId);
          } else {
            ShowSuccessNotification(values.requestName + ' successfully Saved.');
            refetchRequestTemplates();
          }
          // onTemplateClose(tenantId);
        } catch (error) {
          ShowErrorNotification('Error creating template', error);
        }
      }
    } catch (error) {
      ShowErrorNotification('Error creating template', error);
    } finally {
      setLoading(false);
    }
  });
  const handleSend = handleSubmit((values) => {
    // Perform validations
    const CreateRequestErrors = validateCreateRequestTab(values);
    const ReceipentsErrors = validateAddRecipientsTab();

    // Merge both errors
    const combinedErrors = { ...CreateRequestErrors, ...ReceipentsErrors };

    // If there are any errors, set them in validationErrors
    if (Object.keys(combinedErrors).length !== 0) {
      setValidationErrors(combinedErrors);
    } else {
      // If there are no errors, clear the validationErrors
      setValidationErrors({});
      // Show the send confirmation modal
      setSendConfirmModal(true);
    }
  });

  // Function to handle the Yes click
  const handleYesClick = () => {
    CreateRequestSubmitClick(formData, projectDetails, documentTypes);
    setSendConfirmModal(false);
  };

  // Function to close the modal when No is clicked
  const handleNoClick = () => {
    setSendConfirmModal(false);
  };

  const CreateRequestSubmitClick = async (formData: any, projectDetails: any, documentTypes: any) => {
    const { requestDetails } = formData;
    let mapProjectRequest: any = {
      requestName: '',
      requestDescription: '',
      reminderId: '',
      requestDetails: [],
      document: [],
      requestSendTo: [],
      tenentId: 0,
      projectId: 0,
    };
    mapProjectRequest.requestName = formData.requestName;
    mapProjectRequest.requestDescription = formData.requestDescription;
    mapProjectRequest.requestDetails = [];
    mapProjectRequest.requestSendTo = [];
    mapProjectRequest.projectId = projectDetails?.project.projectId;
    mapProjectRequest.tenentId = projectDetails.project.tenantId;
    mapProjectRequest.statusAutoId = defaultRequestStatusId;
    mapProjectRequest.reminderId = reminderListData?.[0].remindersAutoId;
    requestDetails.forEach((reqDetails: any) => {
      mapProjectRequest.requestDetails.push({
        label: reqDetails.label,
        documentTypeAutoId: reqDetails.documentTypeAutoId,
        instructions: reqDetails.instructions,
        attachedFiles: reqDetails.attachedFiles,
      });
      selectedUsers?.forEach((user: any) => {
        mapProjectRequest.requestSendTo.push({
          sendTo: user.email,
          sendCC: '',
          status: defaultRequestStatusId,
          label: reqDetails.label,
          instructions: reqDetails.instructions,
          peopleId: user.peopleId,
          documentTypeAutoId: reqDetails.documentTypeAutoId,
        });
      });
    });

    try {
      setLoading(true);
      const data: any = await ProjectService.postprojectRequestWithDetails<Request>(mapProjectRequest);
      if (data.code === '500') {
        ShowErrorNotification('Error while creating Request', data);
      } else {
        ShowSuccessNotification('Request created successfully');
        onRequestCreated(mapProjectRequest);
      }
      handleDismiss();
    } catch (error) {
      ShowErrorNotification('Error while creating Request', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle file selection
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const files = Array.from(event.target.files || []);
    setSelectedFiles((prevFiles) => {
      const updatedFiles = prevFiles.map((fileList, i) => (i === index ? [...fileList, ...files] : fileList));
      return updatedFiles; // Return the updated state
    });
  };

  // Handle file removal
  const handleFileRemove = (fileToRemove: File, index: number) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.map((fileList, i) => (i === index ? fileList.filter((file: File) => file !== fileToRemove) : fileList)),
    );
  };

  const removeFileSection = (sectionIndex: number) => {
    setSelectedFiles((prevSelectedFiles) => {
      // Create a copy of `selectedFiles`
      const updatedFiles = [...prevSelectedFiles];

      // Remove the entire section's files based on `sectionIndex`
      updatedFiles.splice(sectionIndex, 1);

      // Return the updated array without the section's files
      return updatedFiles;
    });
  };

  return (
    <Sheet show={isOpen} onDismiss={handleDismiss}>
      <Modal title='Send request confirmation' show={sendConfirmModal} onDismiss={handleNoClick} size={'small'}>
        <Flex flexDirection='column' columnGap={4}>
          <Text>You are about to send this request to all recipients.</Text>
          <Flex flexDirection='row' justifyContent='flex-end' rowGap={4}>
            <Button width='80px' variant='default' onClick={handleNoClick}>
              Cancel
            </Button>
            <Button width='80px' variant='accent' color='primary' onClick={handleYesClick}>
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Modal>
      <LoadingStateComponent loading={loading || isTemplatesLoading || isRefetching} />
      <form onSubmit={handleSubmit(saveDetails)} onReset={() => reset()} noValidate>
        <TitleBar>
          <TitleBar.Title> {'Create New Request'} </TitleBar.Title>
          <TitleBar.Action style={{ display: 'flex', alignItems: 'center' }}>
            <Flex justifyContent='flex-end' gap={8}>
              <Button
                disabled={currentTabIndex !== 0}
                width='200px'
                variant='emphasized'
                color='primary'
                onClick={() => {
                  handleSaveTemplate();
                }}
                type='button'
              >
                Save as template
              </Button>
              <VerticalLine />
              <Button width='80px' variant='default' onClick={handleDismiss}>
                Cancel
              </Button>

              <Button width='80px' variant='emphasized' onClick={disablePrevTab} disabled={activeTab === 'Request'}>
                Previous
              </Button>
              {activeTab === 'Request' && (
                <Button width='80px' variant='emphasized' type='submit'>
                  Next
                </Button>
              )}
              {activeTab !== 'Request' && (
                <Button
                  width='80px'
                  variant='emphasized'
                  onClick={handleNextButtonClick}
                  disabled={activeTab === 'Request overview'}
                >
                  Next
                </Button>
              )}

              <Button
                width='80px'
                variant='accent'
                color='primary'
                onClick={() => handleSend()}
                disabled={tabOptions['Request overview'] === true}
              >
                Send
              </Button>
            </Flex>
          </TitleBar.Action>
        </TitleBar>
        <Flex flexDirection='row' gap={8} alignItems='end' justifyContent='flex-end'></Flex>
        <Tabs selectedIndex={currentTabIndex} onChange={handleTabChange}>
          {loading ? (
            <LoadingStateComponent loading={true} />
          ) : (
            <Tab
              title={'Request'}
              prefixIcon={
                Object.keys(createRequestErrors).length !== 0 || validationErrors['Request description'] ? (
                  <CriticalIcon style={{ color: Colors.Icon.Critical.Default }} />
                ) : (
                  <RequestIcon />
                )
              }
              disabled={tabOptions['Request']}
            >
              <RequestMessage
                control={control}
                handleTemplateChange={handleTemplateChange}
                requestTemplateData={requestTemplateData}
                fields={fields}
                selectedFiles={selectedFiles}
                handleFileRemove={handleFileRemove}
                handleAddMore={handleAddMore}
                handleFileChange={handleFileChange}
                fileInputRefs={fileInputRefs}
                remove={remove}
                removeFileSection={removeFileSection}
              />
            </Tab>
          )}

          <Tab
            title='Recipients'
            prefixIcon={
              validationErrors['recipients'] ? (
                <CriticalIcon style={{ color: Colors.Icon.Critical.Default }} />
              ) : (
                <MailIcon />
              )
            }
            disabled={tabOptions['Recipients']}
          >
            <RequestRecipients
              projectDetails={projectDetails}
              peopleList={peopleList}
              recipients={recipients}
              handleSubmit={handleSubmit}
              validationErrors={validationErrors}
              selectedRows={selectedRows}
              updateProjectPeopleRelations={updateProjectPeopleRelations}
              handleRecipientsChange={handleRecipientsChange}
            />
          </Tab>

          <Tab title='Request overview' prefixIcon={<SourceIcon />} disabled={tabOptions['Request overview']}>
            <RequestSummary formValues={formValues} formData={formData} selectedFiles={selectedFiles} />
          </Tab>
        </Tabs>
      </form>
      <FormFieldMessages />
    </Sheet>
  );
};

export default CreateRequest;
